import { Resource } from '../i18n';

export const it: Resource = {
  account: {
    coupons: {
      active: 'Attivo',
      code: 'Codice',
      description: 'Descrizione',
      empty: 'Nessun buono sconto',
      expired: 'Scaduto',
      expiryDate: 'Data di scadenza',
      numberOfUses: 'Numero di utilizzi',
      status: 'Stato',
    },
    create: 'Crea il tuo account',
    creditCards: {
      addCreditcard: 'Aggiungi carta di credito o debito',
      noCardRegistered: 'Nessuna carta di credito registrata',
    },
    edit: 'Modifica',
    emptyWishlist: 'Nessun prodotto aggiunto alla wishlist',
    info: {
      additionalInformation: {
        children: {
          birthdate: 'Data di nascita',
          name: 'Nome',
          sex: 'Genere',
          surname: 'Cognome',
          title: 'Bambino',
        },
        document: {
          authorityDescription: 'Descrizione autorità',
          authorityType: 'Tipo autorità',
          districtName: 'Nome distretto',
          districtType: 'Tipo distretto',
          documentNumber: 'Numero documento',
          documentType: 'Tip documento',
          releaseDate: 'Data di rilasco',
          title: 'Documento',
        },
        other: 'Altro',
        title: 'Informazioni addizionali',
      },
      birthdate: 'Data di nascita',
      businessType: {
        label: 'Tipo attività',
        options: {
          COMPANY: 'Azienda',
          PROPRIETORSHIP: 'Ditta individuale / Professionista',
        },
      },
      company: 'Ragione sociale',
      editPassword: 'Modifica password',
      email: 'Email',
      loginData: 'Dati di login',
      name: 'Nominativo',
      pec: 'Indirizzo PEC',
      personalAddresses: 'Indirizzi',
      personalData: 'Dati personali',
      phone: 'Cellulare',
      profile: {
        label: 'Profilo',
        options: {
          BUSINESS: 'Business',
          PRIVATE: 'Privato',
        },
      },
      secondaryPhone: 'Altro telefono',
      sex: {
        options: {
          F: 'Donna',
          M: 'Uomo',
          O: 'Non specificato',
        },
        title: 'Genere',
      },
      surname: 'Cognome',
      taxCode: 'Codice fiscale',
      title: 'Dati di fatturazione',
      uniqueCode: 'Codice SDI',
      vatNumber: 'Partita IVA',
    },
    insertCredentials: 'Se sei registrato inserisci la tua email e password',
    login: 'Accedi',
    logout: 'Esci',
    loyalty: {
      pointsBalance: 'il tuo saldo punti',
      pointsTable: {
        awardsLabel: 'Premi',
        couponLabel: 'Codice sconto',
        footerText: 'Utilizza il codice sconto in fase di acquisto',
        pointsLabel: 'Punti',
        ptLabel: 'pt',
      },
      shopping: 'inizia lo shopping',
    },
    newCustomer: 'Sei un nuovo cliente?',
    orders: {
      details: {
        closeOrderDetails: 'Chiudi',
        code: 'Codice',
        color: 'Colore:',
        date: 'Data',
        invoiceDownload: 'Scarica fattura',
        invoiceNotReady: 'Fattura non ancora pronta',
        paymentAddress: 'Pagamento',
        price: 'Prezzo',
        product: 'Prodotto',
        quantity: 'Quantità:',
        receiverShipping: 'Destinatario',
        review: 'Riepilogo',
        shippingAddress: 'Spedizione',
        showOrderDetails: 'Visualizza dettaglio ordine',
        size: 'Taglia:',
        status: 'Stato',
        total: 'Totale',
        totalAmount: 'totale da pagare: ',
        totalProducts: 'totale prodotti:',
        totalShipping: 'spedizione:',
        trackingCode: 'Tracking spedizione',
        unitPrice: 'Prezzo unitario',
      },
      noOrders: 'Al momento non ci sono ordini',
      status: {
        arrived: 'Consegnato',
        cancelled: 'Cancellato',
        preparing: 'In preparazione',
        readyToShipping: 'Pronto per la spedizione',
        shipping: 'Spedito',
        waitPayment: 'In attesa di pagamento',
        waitingAcceptance: 'In accettazione',
      },
      trackOrdersState: 'Segui lo stato dei tuoi ordini',
    },
    passwordForgotten: 'Password dimenticata?',
    register: 'Registrati',
    sectionDisabled: 'Sezione disabilitata',
    taxBenefits: {
      alerts: {
        danger: 'I documenti non sono stati approvati',
        success: 'Utente abilitato',
        warning: 'I documenti sono in fase di approvazione',
      },
    },
    userRegistered: 'Sei un cliente registrato?',
    welcomeMessage: 'Ciao {{name}}, scopri la tua area personale',
    wishlist: 'Sposta nella wishlist',
  },
  alert: {
    CART_DIFFERENT_SKU_PRICE: '',
    CART_MINIMUM_COST: 'Importo minimo carrello non raggiunto',
    CART_MIN_CAR_SELLER_VIOLATED: '',
    CART_REGION_NOT_REACHABLE_EXTRA_FRESH: '',
    CART_SKU_NOT_SHIPPABLE_IN_COUNTRY_VIOLATED: 'Questo prodotto non è vendibile nel Paese selezionato',
    GUEST_CHECKOUT_NOT_ALLOWED: '',
    INVOICE_MANDATORY_MISSING_DATA: 'È stata richiesta la fattura ma mancano dei dati di fatturazione',
    MISSING_BILLING_ADDRESS: "Manca l'indirizzo di fatturazione",
    MISSING_EMAIL: 'Manca da inserire la mail',
    MISSING_PAYMENT_METHOD: 'Manca da selezionare il metodo di pagamento',
    MISSING_SHIPPING_ADDRESS: "Manca l'indirizzo di spedizione",
    MISSING_SHIPPING_PROFILE: 'Manca da selezionare il profilo di spedizione',
    UNSOLICITED_INVOICE: "Attenzione, non è stata richiesta l'emissione della fattura per questo ordine",
    WAREHOUSEITEM_NOT_IN_STOCK: '',
    WAREHOUSESKU_NOT_AVAILABLE: 'Prodotto non disponibile per la quantità richiesta. Disponibilità residua: {{maxQty}}',
  },
  api: {
    error: {
      AddingSkuNotAllowed: 'Non è possibile acquistare questo prodotto.',
      AddingSkuNotCompatible:
        'Questo prodotto non può essere spedito insieme agli altri prodotti presenti nel tuo carrello.',
      AddingSkuNotSellable: 'Il prodotto che si sta tentando di aggiungere non è vendibile.',
      CouponAlreadyUsedException: 'Coupon già utilizzato',
      CouponCartRangeException: 'Il coupon non è applicabile a questo carrello',
      CouponInvalidException:
        'Il codice inserito non corrisponde a nessun coupon o non è applicabile a questo carrello',
      CouponLoggedGuestException: 'Per usare il coupon accedi o registrati al sito',
      CustomerCannotSetFeedbackException:
        'Non abbiamo trovato nessun ordine tra i tuoi acquisti contenente questo prodotto',
      CustomerEmailAlreadyExistException: 'Email già utilizzata',
      CustomerEmailNotValid: 'Email inserita non valida',
      CustomerEmailNotVerifiedException:
        "Per poter proseguire, è necessario confermare la registrazione mediante il link che può trovare all'interno della mail ricevuta successivamente alla registrazione.",
      CustomerNotFoundException:
        "L'indirizzo inserito non risulta all'interno dei nostri sistemi, la preghiamo di controllare e riprovare.",
      CustomerUsernameAlreadyExistException: 'Username già utilizzato',
      DataReferenceException: 'Errore interno',
      DepositPaymentCardNoRemainingCreditException: 'Nessun credito residuo',
      DepositPaymentCardNotFoundException: 'Codice non riconosciuto',
      DepositPaymentCardNotPermittedOnCartException: "Non è possibile utilizzare la gift card per acquistarne un'altra",
      EJBException: 'Errore interno',
      LoginException: 'Username o Password non valide',
      LoginUserNotFoundException: 'Email non trovata',
      SystemException: 'Errore interno',
      TaxCodeCheckDigitException:
        'Il codice fiscale inserito non è valido, la preghiamo di ricontrollare il valore inserito e riprovare.',
      TaxCodeLenghtException:
        'Il codice fiscale inserito non è valido, la preghiamo di ricontrollare il valore inserito e riprovare.',
      VatNumberCheckDigitException:
        'La partita iva inserita non è valida, la preghiamo di ricontrollare il valore inserito e riprovare.',
      VatNumberLenghtException:
        'La partita iva inserita non è valida, la preghiamo di ricontrollare il valore inserito e riprovare.',
      title: 'Errore',
      usernotlogged: 'sessione scaduta',
    },
  },
  assembledProduct: {
    addToAssembly: "Aggiungi all'assemblaggio",
    addToCart: 'Aggiungi al carrello',
    completeTheAssembly: "Completa l'assemblaggio",
    discountUpTo: 'Fino al {{percentage}}% di sconto',
    discountUpToMini: 'Fino al {{percentage}}%',
    freeShipping: 'Spedizioni gratuite',
    removeFromAssembly: 'Elimina articolo',
    summary: {
      actions: {
        addToCart: 'Acquista',
        next: 'Prosegui',
        previous: 'Indietro',
      },
      badges: {
        freeShipping: 'Spedizioni gratuite',
        totalDiscount: 'Stai risparmiando {{value}}',
      },
      infoMessage: {
        addAtLeast: '',
        addAtLeast_one: 'Aggiungi almeno un prodotto',
        addAtLeast_other: 'Aggiungi almeno {{count}} prodotti',
        addMoreProducts: '',
        addMoreProducts_one: 'Aggiungi un altro prodotto per proseguire',
        addMoreProducts_other: 'Aggiungi altri {{count}} prodotti per proseguire',
        completeAllStepToContinue: 'Per proseguire devi completare tutti gli step',
        completeStepToContinue: 'Per proseguire devi completare lo step',
      },
      status: '{{productAdded}}/{{maxProduct}} prodotti aggiunti',
      subtitle: 'Riepilogo del tuo assemblaggio',
      title: 'Il tuo totale',
      total: 'Totale',
      yourAssembly: 'Il tuo assemblaggio',
    },
    totalPieces: '{{total}} pezzi',
  },
  bankAccountDetails: {
    bankName: 'Banca',
    bicSwiftCode: 'BIC/SWIFT',
    ibanCode: 'IBAN',
    ownerName: 'Beneficiario',
  },
  cart: {
    badges: {
      discountValue: 'Stai risparmiando {{value}}',
      freeShipping: 'Spedizioni gratuite',
    },
    empty: {
      description: 'Inizia a cercare qualcosa',
      title: 'Il tuo carrello è vuoto',
    },
    loyaltyPointsEarned: 'Il tuo carrello può farti guadagnare <strong>{{loyaltyPointsEarned}} punti fedeltà</strong>',
    modal: {
      shippingCountry: {
        alert: 'Attenzione! Cambiando paese il tuo carrello verrà svuotato',
        title: 'Vuoi cambiare paese di spedizione?',
      },
    },
    paymentMethods: {
      title: 'Modalità di pagamento disponibili',
    },
    productTile: {
      composedOf: 'Composto da:',
      removeItem: 'Elimina articolo',
      sendToWishlist: 'Sposta nella wishlist',
    },
    proocedToCart: 'Vai al carrello',
    quantity: 'Quantità',
    summary: {
      additional: {
        cashOnDelivery: 'Pagamento alla consegna',
        title: 'Supplementi',
      },
      continueShopping: 'continua lo shopping',
      coupons: {
        removeCoupon: 'Rimuovi',
        title: 'Inserisci un codice sconto (facoltativo)',
      },
      freeShipping: 'Gratis',
      freeShippingFrom: 'Spedizioni gratuite a partire da {{freeShippingFromAmount}}',
      giftCard: 'Carte regalo',
      proocedToCheckout: 'Prosegui',
      shippingCountry: {
        edit: 'Cambia',
        title: 'Paese di spedizione',
      },
      shippingProfiles: {
        title: 'Spedizione',
      },
      subtotal: 'Subtotale',
      title: 'Il tuo ordine',
      toFreeShipping: 'Mancano <strong>{{toFreeShippingAmount}}</strong> per la spedizione gratuita',
      toFreeShippingComplete: 'Hai raggiunto la <b>spedizione gratuita</b>',
      totalBillingCost: 'Totale (IVA inclusa)',
      totalCost: 'Totale da pagare',
      totalDiscount: 'Sconti',
      totalProductsCost: 'Costo prodotti',
    },
    title: 'Il mio carrello',
  },
  checkout: {
    backToCart: 'Torna al carrello',
    billing: {
      addNewAddress: 'Aggiungi nuovo indirizzo',
    },
    cancel: 'Annulla',
    cannotStartPayment: 'Ops, qualcosa è andato storto nel tuo carrello, verifica gli articoli per poi procedere',
    edit: 'Modifica',
    invoice: {
      notRequested: 'Non hai chiesto la fattura',
      requested: 'Hai chiesto la fattura',
    },
    shipping: {
      addNewAddress: 'Aggiungi nuovo indirizzo',
    },
    step: {
      billingAddress: {
        subTitle: 'Coincide con quello di consegna',
        title: 'Indirizzo di pagamento',
      },
      email: {
        alert: {
          buttons: {
            label: '',
          },
          title: '',
        },
        insertCredentials: "Inserisci le tue credenziali e completa l'accesso",
        login: 'Accedi',
        orderAs: 'Stai ordinando come',
        subTitle1: ' Completa il tuo ordine più velocemente.',
        subTitle2: 'Accedi con il tuo account',
        title: 'Email',
      },
      paymentMethod: {
        subTitle: 'Scegli il metodo di pagamento',
        title: 'Metodo di pagamento',
      },
      shippingAddress: {
        title: 'Indirizzo di consegna',
      },
    },
    summary: {
      additional: {
        cashOnDelivery: 'Pagamento alla consegna',
        title: 'Supplementi',
      },
      cartItem: {
        edit: 'Modifica',
        title: 'Stai acquistando',
      },
      coupon: {
        add: 'Aggiungi',
        edit: 'Modifica',
        title: 'Coupon',
      },
      giftCard: {
        add: 'Aggiungi',
        edit: 'Modifica',
        title: 'Carta regalo',
      },
      shipping: {
        title: 'Spedizione',
      },
      shippingCountry: {
        title: 'Paese di spedizione',
      },
      subtotal: 'Subtotale',
      title: 'Il tuo ordine',
      totalBillingCost: 'Totale (IVA inclusa)',
      totalCost: 'Totale da pagare',
      totalProductsCost: 'Totale prodotti',
    },
    thankYou: {
      cashOnDelivery: {
        amountToPayOnDelivery: 'Importo da pagare alla consegna',
      },
    },
  },
  contactUs: {
    customerOrder: {
      label: 'Per quale ordine ti serve aiuto?',
      placeholder: 'Seleziona il tuo ordine',
    },
    notSatisfied: {
      label: 'Non hai trovato la risposta che stavi cercando? Contattaci!',
    },
    satisfied: {
      label: 'Hai trovato la risposta che stavi cercando?',
      no: 'no',
      yes: 'si',
    },
    subject: {
      label: 'Di quale informazione hai bisogno?',
      placeholder: "Seleziona un'opzione",
    },
  },
  customerCare: {
    contact: 'Contattaci',
    help: 'Ti possiamo aiutare?',
    select: {
      label: 'Di quale informazione hai bisogno?',
      optionLabel: "Seleziona un'opzione",
    },
    service: "Consulta l'area Servizio Clienti per un aiuto facile e veloce",
  },
  error: {
    pageNotFound: 'Ops. Non abbiamo trovato la pagina che cercavi',
  },
  fixedPagesName: {
    account: 'account',
    cart: 'cart',
    'change-password': 'change-password',
    checkout: 'checkout',
    error: 'error',
    'forgot-password': 'forgot-password',
    home: 'home',
    login: 'login',
    newsletter: 'newsletter',
    'order-ko': 'order-ko',
    'order-ok': 'order-ok',
    register: 'register',
    search: 'search',
    sitemap: 'sitemap',
    template: 'template',
    test: 'test',
    'unsubscribe-newsletter': 'unsubscribe-newsletter',
    whois: 'whois',
  },
  footer: {
    paymentsMethod: 'Metodi di pagamento',
  },
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLong: { label: 'Avvisami quando disponibile' },
          addShort: { label: 'Avvisami' },
          remove: { label: "Elimina l'avviso" },
        },
        cancel: { label: 'Annulla' },
        reset: { label: 'Reset' },
        submit: { label: 'Aggiungi al carrello' },
        submitButNotAvailable: { label: 'Non disponibile' },
      },
      fields: {
        giftCard: {
          message: {
            label: 'Messaggio',
            placeholder: '',
          },
          receiverEmail: {
            label: 'Email del destinatario',
            placeholder: '',
          },
          receiverName: {
            label: 'Nome del destinatario',
            placeholder: '',
          },
          senderName: {
            label: 'Il tuo nome',
            placeholder: '',
          },
          value: {
            placeholder: "Seleziona un'opzione",
          },
        },
        quantity: {
          label: 'Quantità',
          placeholder: 'Seleziona una delle opzioni',
        },
        skuAttributes: {
          Gift_card_valore: {
            placeholder: 'Seleziona il valore',
          },
        },
        skus: {
          label: 'Skus',
          placeholder: 'Seleziona uno sku',
        },
      },
      misc: {
        productVariants: 'Formato',
      },
      modal: {
        title: '',
      },
      outcome: {
        failure: {
          AddingSkuNotCompatible: {
            body: 'Questo prodotto non può essere spedito insieme agli altri prodotti presenti nel tuo carrello. Vuoi procedere svuotando il carrello attuale?',
            buttons: {
              no: { label: 'No' },
              yes: { label: 'Si' },
            },
            title: 'Ci dispiace',
          },
        },
        success: {
          body: 'Prodotto aggiunto al carrello',
          buttons: {
            continueShopping: { label: 'Continua lo shopping' },
            goToCart: { label: 'Vai al carrello' },
          },
          title: 'Prodotto aggiunto al carrello',
        },
      },
    },
    addressForm: {
      account: {
        defaultBilling: {
          label: 'Imposta come indirizzo predefinito di pagamento',
        },
        defaultShipping: {
          label: 'Imposta come indirizzo predefinito di spedizione',
        },
      },
      address: {
        addressLine1: {
          label: 'Indirizzo',
          placeholder: '',
        },
        addressLine2: {
          label: 'Interno, piano, c/o, etc...',
          placeholder: '',
        },
        addressLine3: {
          label: '',
          placeholder: '',
        },
        alternativePhone: {
          label: 'Telefono alternativo',
          placeholder: '',
        },
        city: {
          label: 'Città',
          placeholder: '',
        },
        civicNumber: {
          label: 'Civico',
          placeholder: '',
        },
        country: {
          label: 'Nazione',
          placeholder: '',
        },
        latitude: {
          label: 'Latitudine',
          placeholder: '',
        },
        longitude: {
          label: 'Longitudine',
          placeholder: '',
        },
        middleName: {
          label: 'Secondo nome',
          placeholder: '',
        },
        name: {
          label: 'Nome',
          placeholder: '',
        },
        phone: {
          label: 'Telefono',
          placeholder: '',
        },
        province: {
          label: 'Provincia',
        },
        region: {
          label: 'Regione',
          placeholder: '',
        },
        shippingToAlert: 'Stai spedendo in {{locale}}, torna indietro per cambiare paese di spedizione.',
        surname: {
          label: 'Cognome',
          placeholder: '',
        },
        zipCode: {
          label: 'CAP',
        },
      },
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        edit: {
          label: 'Modifica',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Aggiungi',
        },
      },
      copyFromBilling: {
        label: 'Usa lo stesso indirizzo di pagamento',
      },
      copyFromShipping: {
        label: 'Usa lo stesso indirizzo di spedizione',
      },
      copyToBilling: {
        label: 'Imposta anche come indirizzo di pagamento',
      },
      copyToShipping: {
        label: 'Imposta anche come indirizzo di spedizione',
      },
      editAddressTitle: 'Modifica indirizzo',
      invoice: {
        data: {
          company: {
            label: 'Ragione sociale',
            placeholder: '',
          },
          pec: {
            label: 'PEC',
            placeholder: '',
          },
          taxCode: {
            label: 'Codice fiscale',
            placeholder: '',
          },
          uniqueCode: {
            label: 'Codice SDI',
            placeholder: '',
          },
          vatNumber: {
            label: 'Partita IVA',
            placeholder: '',
          },
        },
        request: {
          label: 'Vuoi la fattura?',
        },
        type: {
          label: 'Tipologia cliente',
          options: {
            company: 'Azienda',
            freelance: 'Ditta individuale / Professionista',
            privatePerson: 'Privato',
          },
        },
      },
      modal: {
        title: '',
      },
      newAddressTitle: 'Inserisci un nuovo indirizzo',
    },
    cartAddressFromAddressBookForm: {
      buttons: {
        cancel: { label: 'Annulla' },
        editAddress: { label: 'Modifica indirizzo' },
        newAddress: { label: 'Aggiungi nuovo indirizzo' },
        reset: { label: 'Reset' },
        submit: { label: 'Conferma' },
      },
    },
    cartEmailForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          checkout: {
            label: 'Modifica Email',
          },
          whoIs: {
            label: "Procedi con l'ordine",
          },
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
        newsletterConsent: {
          label: '',
          radios: {
            false: '',
            true: '',
          },
        },
        privacy: {
          label: 'Acconsento al trattamento dei dati personali.',
        },
      },
      handlers: {
        registeredEmail: {
          body: "L'email inserita risulta essere registrata, sei tu?<br/>Inserisci la password per completare la login e associare l'ordine al tuo account.",
          buttons: { cancel: { label: 'No grazie' }, submit: { label: 'Login' } },
          title: 'Ci conosciamo?',
        },
      },
      modal: {
        title: '',
      },
    },
    cartGenericCouponForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Applica',
        },
      },
      fields: {
        code: {
          label: '',
          placeholder: 'Inserisci codice',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPaymentMethodsForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Conferma',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPersonalCouponsForm: {
      misc: {
        noCoupon: '',
      },
      modal: {
        title: '',
      },
    },
    changePasswordByTokenForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Invia richiesta',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Conferma nuova password',
          placeholder: 'Conferma nuova password',
        },
        newPassword: {
          label: 'Nuova password',
          placeholder: 'Nuova password',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'La procedura di cambio password si è conclusa con successo',
          title: 'Conferma cambio password',
        },
      },
    },
    changePasswordForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Invia richiesta',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Conferma nuova password',
          placeholder: 'Conferma nuova password',
        },
        currentPassword: {
          label: 'Password attuale',
          placeholder: 'Password attuale',
        },
        newPassword: {
          label: 'Nuova password',
          placeholder: 'Nuova password',
        },
      },
      modal: {
        title: 'Cambio password',
      },
      outcome: {
        success: {
          body: 'La procedura di cambio password si è conclusa con successo',
          title: 'Conferma cambio password',
        },
      },
    },
    consentsForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Salva',
        },
      },
      fields: {
        marketing: {
          label: 'Accettazione marketing',
          radios: {
            false: 'No',
            true: 'Si',
          },
        },
        newsletter: {
          label: 'Accettazione newsletter',
          radios: {
            false: 'No',
            true: 'Si',
          },
        },
        profiling: {
          label: 'Accettazione profilazione',
          radios: {
            false: 'No',
            true: 'Si',
          },
        },
      },
      modal: {
        title: '',
      },
    },
    customerCareForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Invia',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: '',
        },
        message: {
          label: 'Messaggio',
          placeholder: '',
        },
        name: {
          label: 'Nome',
          placeholder: '',
        },
        phoneNumber: {
          label: 'Telefono',
          placeholder: '',
        },
        privacy: {
          label: 'Dichiaro di aver letto e accettato l/informativa privacy',
          placeholder: '',
        },
        surname: {
          label: 'Cognome',
          placeholder: '',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Messaggio inviato correttamente',
          title: 'Invio messaggio',
        },
      },
    },
    customerForm: {
      birthdate: {
        errors: {
          minAge: 'Inserisci una data di nascita valida',
          minAgeWithDate: '',
          minAgeWithDate_one: 'Devi aver compiuto almeno {{count}} anno',
          minAgeWithDate_other: 'Devi aver compiuto almeno {{count}} anni',
        },
        label: 'Data di nascita',
      },
      businessType: {
        label: "Com'è registrata la tua attività",
        options: {
          company: 'Azienda',
          proprietorship: 'Ditta individuale / Professionista',
        },
        placeholder: "Seleziona un'opzione",
      },
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          registrationLabel: 'Registrati',
          updateLabel: 'Aggiorna',
          updateRequiredFieldsLabel: 'Completa il profilo',
        },
      },
      children: {
        birthdate: {
          errors: {
            minAge: 'Inserisci una data di nascita valida',
            minAgeWithDate: '',
            minAgeWithDate_one: 'Deve aver compiuto almeno {{count}} anno',
            minAgeWithDate_other: 'Deve aver compiuto almeno {{count}} anni',
          },
          label: 'Data di nascita',
          placeholder: 'Data di nascita',
        },
        buttons: {
          add: {
            label: 'Aggiungi bambino',
          },
          remove: {
            label: 'Rimuovi bambino',
          },
        },
        formGroup: {
          label: 'Bambini (min: {{min}} max: {{max}} attuali: {{current}})',
        },
        name: {
          label: 'Nome',
          placeholder: 'Nome',
        },
        sex: {
          label: 'Sesso',
          options: {
            female: 'Femmina',
            male: 'Maschio',
            other: 'Preferisco non specificarlo',
          },
          placeholder: "Seleziona un'opzione",
        },
        surname: {
          label: 'Cognome',
          placeholder: 'Cognome',
        },
      },
      company: {
        label: 'Ragione sociale',
        placeholder: 'Ragione sociale',
      },
      document: {
        authorityDescription: {
          label: "Descrizione dell'autorità",
          placeholder: "Descrizione dell'autorità",
        },
        authorityType: {
          label: 'Tipo di autorità',
          options: {
            consulate: 'Consolato',
            financeOffice: 'Ufficio finanziario',
            foreignState: 'Stato estero',
            motorization: 'Motorizzazione',
            municipality: 'Comune',
            other: 'Altro',
            prefecture: 'Prefettura',
            unknown: 'Sconosciuto',
          },
          placeholder: 'Tipo di autorità',
        },
        districtName: {
          label: 'Nome distretto',
          placeholder: 'Nome distretto',
        },
        districtType: {
          label: 'Tipo distretto',
          placeholder: 'Tipo distretto',
        },
        documentNumber: {
          label: 'Numero documento',
          placeholder: 'Numero documento',
        },
        documentType: {
          label: 'Tipo di documento',
          options: {
            drivingLicense: 'Patente di guida',
            identityCard: "Carta d'identità",
            passport: 'Passaporto',
          },
          placeholder: 'Tipo documento',
        },
        label: 'Documento',
        releaseDate: {
          label: 'Data di rilascio',
          placeholder: 'Data di rilascio',
        },
      },
      email: {
        label: 'Email',
        placeholder: 'Email',
      },
      enableBusinessData: {
        formGroup: {
          label: 'Desideri aggiungere i dati della tua attività?',
        },
        options: {
          no: 'No',
          yes: 'Si',
        },
      },
      enableChildren: {
        formGroup: {
          label: 'Desideri aggiungere dei bambini?',
        },
        options: {
          no: 'No',
          yes: 'Si',
        },
      },
      enableDocument: {
        formGroup: {
          label: 'Desideri aggiungere un documento?',
        },
        options: {
          no: 'No',
          yes: 'Si',
        },
      },
      marketingConsent: {
        label: 'Marketing',
      },
      modal: {
        title: 'Dati personali',
      },
      name: {
        label: 'Nome',
        placeholder: 'Nome',
      },
      newsletterConsent: {
        label: 'Newsletter',
      },
      password: {
        label: 'Password',
        placeholder: 'Password',
      },
      pec: {
        label: 'Indirizzo PEC',
        placeholder: 'Indirizzo PEC',
      },
      phoneNumbers: {
        buttons: {
          add: {
            label: 'Aggiungi telefono',
          },
          remove: {
            label: 'Rimuovi telefono',
          },
        },
        label: 'Telefono',
        placeholder: 'Telefono',
      },
      privacyConsent: {
        label: 'Privacy',
      },
      profile: {
        label: 'Profilo',
        options: {
          business: 'Business',
          private: 'Privato',
        },
        placeholder: "Seleziona un'opzione",
      },
      profilingConsent: {
        label: 'Profilazione',
      },
      sex: {
        label: 'Sesso',
        options: {
          female: 'Donna',
          male: 'Uomo',
          other: 'Preferisco non specificarlo',
        },
        placeholder: "Seleziona un'opzione",
      },
      surname: {
        label: 'Cognome',
        placeholder: 'Cognome',
      },
      taxCode: {
        label: 'Codice Fiscale',
        placeholder: 'Codice Fiscale',
      },
      uniqueCode: {
        label: 'Codice SDI',
        placeholder: 'Codice SDI',
      },
      vatNumber: {
        label: 'Partita IVA',
        placeholder: 'Partita IVA',
      },
    },
    emailToCartForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: "Procedi con l'ordine",
        },
      },
      modal: {
        title: '',
      },
    },
    exampleForm: {
      modal: {
        title: '',
      },
    },
    forgotPasswordForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Invia richiesta',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Email',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Operazione conclusa con successo!\n\nTi abbiamo inviato una mail con le istruzioni da seguire.',
          title: 'Richiesta di reset password',
        },
      },
    },
    giftCardForm: {
      buttons: {
        balanceCheck: {
          label: 'Verifica saldo',
        },
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Utilizza',
        },
      },
      fields: {
        code: {
          label: 'Carta regalo',
          placeholder: 'Inserisci il numero di carta regalo',
        },
      },
    },
    localeForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Vai',
        },
      },
      fields: {
        locale: {
          label: 'Lingua',
        },
        shippingCountry: {
          label: 'Paese',
        },
      },
      modal: {
        title: '',
      },
    },
    loginForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Login',
        },
      },
      fields: {
        password: {
          label: 'Password',
          placeholder: 'Password',
        },
        username: {
          label: 'Email',
          placeholder: 'Email',
        },
      },
      modal: {
        title: '',
      },
    },
    newsletterForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Iscriviti alla newsletter',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'Inserisci il tuo indirizzo email',
        },
        name: {
          label: 'Nome',
          placeholder: 'Inserisci il tuo nome',
        },
        newsletterConsent: {
          label: '',
        },
        surname: {
          label: 'Cognome',
          placeholder: 'Inserisci il tuo cognome',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Complimenti! Da adesso anche tu fai parte della newsletter!',
          buttons: {
            ok: {
              label: 'Ok',
            },
          },
          title: 'Iscrizione alla newsletter avvenuta con successo',
        },
      },
    },
    orderGuestForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Cerca',
        },
      },
      fields: {
        email: {
          label: 'Email',
          placeholder: 'email',
        },
        orderCode: {
          label: 'Codice ordine',
          placeholder: 'codice ordine',
        },
      },
      modal: {
        title: '',
      },
    },
    restartPaymentForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Paga adesso',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    },
    reviewForm: {
      buttons: {
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Lascia la recensione',
        },
      },
      fields: {
        message: {
          label: 'Aggiungi un commento',
          placeholder: 'Scrivi la tua recensione',
        },
        rating: {
          label: 'Quanto ti è piaciuto <strong>{{productName}}</strong>?',
        },
      },
      modal: {
        title: 'Dicci cosa ne pensi!',
      },
      outcome: {
        failure: {
          generic: {
            body: 'Non abbiamo trovato nessun ordine tra i tuoi acquisti contenente questo prodotto',
            title: 'Ci dispiace',
          },
        },
        success: {
          body: 'La tua recensione sarà pubblicata dopo essere stata revisionata dal nostro customer care.',
          title: 'Grazie per il tuo feedback!',
        },
      },
    },
    shopSearchForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Cerca',
        },
      },
    },
    simpleSearchForm: {
      fields: {
        text: {
          label: 'Cerca',
          placeholder: 'Cerca',
        },
      },
    },
    startPaymentForm: {
      buttons: {
        cancel: {
          label: 'Annulla',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Acquista',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    },
    taxBenefitsForm: {
      buttons: {
        browseFile: 'Allega documento',
        cancel: 'Annulla',
        reset: {
          label: 'Reset',
        },
        submit: 'Salva documentazione',
      },
      documentValidation: {
        approved: 'Documento convalidato.',
        awating: 'Documento in fase di approvazione.',
        rejected: 'Documento respinto. Carica un nuovo documento valido.',
      },
      documentation: {
        label: 'Scarica la documentazione',
      },
      taxCode: {
        label: 'Codice fiscale',
        placeholder: 'Codice fiscale',
      },
    },
    updateCustomerRequiredFieldsForm: {
      modal: {
        title: '',
      },
    },
  },
  header: {
    cartSummary: '',
    cartSummary_one: 'Riepilogo ordine ({{count}} articolo)',
    cartSummary_other: 'Riepilogo ordine ({{count}} articoli)',
    freeShippingFrom: 'Spedizioni gratuite in {{locale}} da {{from}}',
    sendTo: 'Paese: {{country}} | {{locale}}',
  },
  i18nTest: 'Questo è un test da b2x-react',
  misc: {
    account: 'Account',
    addAddress: 'Aggiungi indirizzo',
    addToCart: 'Aggiungi al carrello',
    amount: 'Importo',
    availabilityEmailNotification: 'Avvisami',
    availabilityNotification: 'Ti avviseremo quando disponibile',
    back: 'Indietro',
    backToHome: 'Torna alla home',
    bestPrice: {
      info: 'info',
      modal: {
        lowestPrice: 'Prezzo piu basso negli ultimi 30 giorni',
        priceToday: 'Quanto costa questo articolo oggi',
        title: "Storico del prezzo dell'articolo",
      },
      priceLabel: 'Ultimo prezzo più basso:',
    },
    categories: 'Categorie',
    changeCountryMessage: 'Attenzione! Cambiando paese il tuo carrello verrà svuotato',
    checkout: 'Checkout',
    code: 'Codice',
    customerForm: {
      registrationSuccessful: {
        modal: {
          body: 'Grazie per esserti iscritto.',
          title: 'Registrazione avvenuta',
        },
      },
    },
    customerService: 'Assistenza clienti',
    directions: 'Indicazioni',
    discoverMore: 'scopri di più',
    discoverOurCollection: 'Scopri la collezione',
    discoverOurProducts: 'Scopri i nostri prodotti',
    doubleOptIn: {
      body: 'Il tuo indirizzo email è stato validato con successo, esegui la Login per iniziare a navigare.',
      title: 'Account verificato',
    },
    edit: 'Modifica',
    fileInput: {
      label: 'Scegli file',
      placeholder: 'Nessun file selezionato',
      uploading: 'uploading...',
    },
    filter: 'Filtra',
    filterAndOrderBy: 'Filtra e ordina per',
    filterBy: 'Filtra per',
    filterYourSearchResults: 'Filtra i risultati della tua ricerca',
    followUs: 'Seguici',
    fromPrice: 'da {{value}}',
    giftCard: {
      balance: {
        activation: 'Attivazione',
        expiration: 'Scadenza',
        title: 'Carta regalo dettaglio',
        value: 'Saldo',
      },
      label: 'Carta regalo',
      message: 'Visualizza il messaggio',
      receiver: {
        label: 'Per:',
        mail: 'Email:',
      },
      sender: {
        label: 'Da:',
        mail: 'Email:',
      },
      value: 'Valore:',
    },
    giftProduct: 'Omaggio',
    gratis: 'Gratis',
    invalid_form_values: 'Verifica tutti i campi prima di poter proseguire',
    invoice: {
      company: 'Ragione sociale',
      pec: 'PEC',
      taxCode: 'Codice fiscale',
      uniqueCode: 'Codice SDI',
      vatNumber: 'Partita IVA',
    },
    locale: {
      box: {
        title: {
          country: 'Seleziona paese e lingua',
          language: 'Seleziona lingua',
        },
      },
    },
    loginRegister: 'Accedi/Registrati',
    myOrders: 'I miei ordini',
    newProductSticker: 'New',
    newsletterTitle: 'Newsletter',
    or: 'Oppure',
    orderBy: 'Ordina per',
    orderCode: 'Codice ordine',
    passwordInput: {
      hide: 'nascondi',
      show: 'mostra',
    },
    payPalCheckout: {
      advanced: {
        privacyNotice:
          'Pagando con la tua carta, riconosci che i tuoi dati saranno trattati da PayPal in conformità con l\'Informativa sulla privacy di PayPal disponibile su <a href="https://www.paypal.com">PayPal.com</a>.',
      },
      capture: {
        errorModal: {
          body: 'Il pagamento non è andato a buon fine.\n\nIl circuito ha rifiutato il pagamento, non è stato effettuato alcun addebito.\n\nTi consigliamo di verificare con la tua banca il motivo del rifiuto.',
          title: 'Errore pagamento',
        },
      },
      form: {
        buttons: {
          cancel: 'Annulla',
          submit: 'Paga',
        },
        fields: {
          cvv: {
            placeholder: 'CVV',
          },
          expiry: {
            placeholder: 'MM / AA',
          },
          name: {
            placeholder: 'Nome (facoltativo)',
          },
          number: {
            placeholder: 'Numero carta',
          },
        },
        state: {
          invalid: 'Il form di pagamento non è valido',
        },
      },
    },
    payments: 'Pagamenti',
    preferences: 'Potrebbe interessarti',
    price: '{{value}}',
    pricePerShape: '{{price}} per {{shape}}',
    pricePerUnit: '{{price}} al {{measurementUnit}}',
    productNotAvailable: 'Non disponibile',
    productsPicker: {
      progress: '',
      progress_one: 'Hai aggiunto {{count}} prodotto su un totale di {{total}} prodotti.',
      progress_other: 'Hai aggiunto {{count}} prodotti su un totale di {{total}} prodotti.',
      select: 'Seleziona',
      selected: 'Selezionato',
    },
    readMore: 'Leggi altro',
    register: 'Registrati adesso',
    relatedProducts: 'Potrebbe interessarti anche',
    removeFilters: 'Rimuovi tutti',
    searchItemResult: '',
    searchItemResult_one: '{{count}} risultato',
    searchItemResult_other: '{{count}} risultati',
    shipment: 'Spedizioni e resi',
    showLess: 'Chiudi',
    showMore: 'Leggi tutto',
    sizeGuide: 'guida alle taglie',
    storeName: '',
    time: {
      days: '',
      days_one: 'Giorno',
      days_other: 'Giorni',
      hours: '',
      hours_one: 'Ora',
      hours_other: 'Ore',
      minutes: '',
      minutes_one: 'Minuto',
      minutes_other: 'Minuti',
      seconds: '',
      seconds_one: 'Secondo',
      seconds_other: 'Secondi',
    },
    unsubscribeNewsletterByToken: {
      pending: 'Disiscrizione dalla newsletter in corso...',
      success: 'La disiscrizione alla newsletter è avvenuta con successo!',
      title: 'Newsletter',
    },
    vat: '(IVA al {{value}}%)',
    viewAll: 'Vedi tutti',
    welcomeMessage: 'Ciao {{name}}',
    wishlist: 'Wishlist',
    youSearchedFor: 'Hai cercato:',
  },
  modal: {
    geolocation: {
      content:
        '{{storeName}} sta per chiederti di accedere alla tua posizione per una migliore fruizione del servizio.',
      title: 'Geolocalizzazione {{storeName}}',
    },
  },
  paymentMethod: {
    ALIAS: 'Pagamento tramite Alias',
    ANTICIPATO_BONIFICO: 'Anticipato con Bonifico Bancario',
    ANTICIPATO_CC: 'Anticipato con Carta di Credito',
    B2X_DIRECT: '',
    BANCOMAT_PAY: 'BANCOMAT Pay',
    BANK_TRANSFER: 'Bonifico Bancario',
    BNL: 'Carta di credito tramite BNL',
    CALL_CENTER: 'Call center',
    CASH: 'CASH',
    CASH_ON_DELIVERY: 'Contrassegno',
    CMCIC: '',
    EASYPAY_CC: '',
    EASYPAY_MB: '',
    EASYPAY_MBWAY: '',
    ECPAY: '',
    FATTURA_30: 'Fattura a 30 giorni',
    FATTURA_60: 'Fattura a 60 giorni',
    FIDELITY_CARD: '',
    GESTPAY: '',
    GESTPAY_MYBANK: '',
    GIFT_CARD: 'Carta regalo',
    KEYCLIENT: 'Carta di credito',
    KLARNA: '',
    MULTIBANCO: '',
    MYBANK: '',
    NOT_NECESSARY_PAYMENT: 'Pagamento non necessario',
    NOT_SETTED_PAYMENT: 'Pagamento non impostato',
    PAYPAL: '',
    PAYPAL_V2: '',
    PAY_ON_PHYSICAL_SHOP: '',
    POS: 'Carta di Credito con POS in loco',
    POSTE_BPIOL: 'Poste BPIOL',
    POSTE_BPOL: 'Poste BPOL',
    POSTE_CC: 'Carta di credito tramite Poste',
    POSTE_PAY: 'PostePay',
    RIBA60: 'RiBa 60',
    RIBA_30: 'RiBa 30',
    RIMESSA_DIRETTA: 'Rimessa Diretta',
    SATISPAY: 'Satispay',
    SCALAPAY: 'Pagamento tramite Scalapay',
    SETEFI: 'Carta di Credito',
    SETEFI_MASTERPASS: 'Masterpass',
    THIRD_PARTIES: 'PostePay',
    WANNME_BT: '',
    WANNME_CC: '',
    WANNME_PAY7: '',
    WANNME_RECURRENT: '',
    WORLDLINE_EASY_CHECKOUT: 'Carta di debito/credito',
  },
  product: {
    caloriesPer100g: 'Calorie per 100 gr',
    code: 'Codice prodotto',
    cookingMinutes: 'Minuti - tempo di cottura',
    description: 'Descrizione',
    details: 'Dettagli prodotto',
    dryingTime: 'Ore di essiccazione certificate',
    features: 'Caratteristiche',
    freeShippingFrom: 'Spedizioni gratuite a partire da <strong>{{freeShippingFromAmount}}</strong>',
    minutesOfCookingAlDente: 'Minuti - al dente',
    promo: 'Promo',
    relatedProduct: {
      title: 'Ti potrebbe piacere anche',
    },
    selectSize: 'Seleziona una taglia',
    share: 'Condividi',
    size: 'Formato',
    sizeGuide: 'Guida alle taglie',
    temporary: {
      shipping: 'Consegna rapida e reso facile entro 30 giorni.',
      sizeGuide: 'Non conosci la tua taglia?',
    },
    usageAndMaintenance: 'Uso e manutenzione',
  },
  reviews: {
    buttons: {
      reviewForFirst: 'Recensisci per primo questo prodotto',
      showMore: 'Mostra altre',
      signIn: 'Accedi',
      signUp: 'Registrati',
      writeReview: 'Lascia una recensione',
    },
    messages: {
      customer: 'Condividi la tua esperienza su questo prodotto.',
      guest: 'Accedi o registrati per lasciare una recensione.',
      withReviwes: 'Hai già provato questo prodotto?',
      withoutReviews:
        'Hai già provato questo prodotto? Condividi per primo il tuo pensiero e facci sapere cosa ne pensi!',
    },
    misc: {
      emptyList: 'Non ci sono recensioni',
      filterBy: 'Filtra per',
      review: '',
      review_one: '{{count}} Recensione',
      review_other: '{{count}} Recensioni',
      star: '',
      star_one: 'Stella',
      star_other: 'Stelle',
      writeReview: 'Lascia una recensione',
    },
    review: {
      notVerified: 'Recensione non verificata',
      purchasedOn: 'Acquistato: {{date}}',
      reviewedOn: 'Recensito: {{date}}',
    },
    titles: {
      withReviwes: 'Tutte le recensioni',
      withoutReviews: 'Lascia una recensione',
    },
  },
  sharingModal: {
    methods: {
      clipboard: {
        label: 'Copia link',
        success: 'Link copiato negli appunti',
      },
      email: {
        label: 'Email',
      },
      facebook: {
        label: 'Facebook',
      },
      messenger: {
        label: 'Messenger',
      },
      whatsApp: {
        label: 'WhatsApp',
      },
      x: {
        label: 'X',
      },
    },
    title: 'Condividi',
  },
  sortingOption: {
    default: 'Rilevanza',
    id: {
      asc: '',
      desc: '',
    },
    name: {
      asc: '',
      desc: '',
    },
    price: {
      asc: 'Prezzo basso',
      desc: 'Prezzo alto',
    },
    priority: {
      asc: '',
      desc: '',
    },
  },
  storeLocator: {
    filterPanel: {
      selectProduct: 'Seleziona il prodotto',
    },
    listingPanel: {
      loading: 'loading...',
      shopsCount: '',
      shopsCount_one: '{{count}} Punto Vendita',
      shopsCount_other: '{{count}} Punti Vendita',
    },
    modal: {
      desktopPhone: {
        content: 'Per maggiori informazioni su {{name}}, contatta il numero {{phoneNumber}}',
        title: 'Contatta il punto vendita',
      },
      gallery: {
        title: 'Foto punto vendita',
      },
      geolocationError: {
        content: 'Geolocalizzazione negata',
        title: 'Impossibile centrare mappa, geolocalizzazione negata',
      },
    },
    shopDetails: {
      closed: 'Chiuso',
      cta: {
        call: 'Chiama',
        directions: 'Indicazioni stradali',
      },
      description: 'Descrizione',
      openingDays: {
        FRIDAY: 'Venerdì',
        MONDAY: 'Lunedì',
        SATURDAY: 'Sabato',
        SUNDAY: 'Domenica',
        THURSDAY: 'Giovedì',
        TUESDAY: 'Martedì',
        WEDNESDAY: 'Mercoledì',
      },
      socialNetwork: 'Social Network',
    },
    shopTile: {
      CLOSED: 'Chiuso',
      OPEN: 'Aperto',
      closesAtDayTime: 'Chiude {{day}} alle {{time}}',
      closesAtTime: 'Chiude alle {{time}}',
      copyLink: 'Copia link',
      linkCopied: 'Link copiato negli appunti',
      opensAtDayTime: 'Apre {{day}} alle {{time}}',
      opensAtTime: 'Apre alle {{time}}',
      share: 'Condividi',
    },
  },
  topSuggestionsBox: {
    categories: 'Categorie',
    popularSearches: 'Ricerche popolari',
    recentSearches: 'Ricerche recenti',
    showAllProducts: 'Scopri tutti i prodotti',
  },
  updateCustomerRequiredFieldsPage: {
    subtitle: 'Completa i tuoi dati e comincia a comprare sullo shop',
    title: 'Ciao',
    titleWithName: 'Ciao {{name}}',
  },
  wishlist: {
    modal: {
      availabilityNotificationDisabled: 'Non verrai più avvisato quando il prodotto tornerà disponibile.',
      availabilityNotificationEnabled: 'Verrai avvisato non appena il prodotto tornerà disponibile!',
      buttons: {
        continueShopping: {
          label: 'Continua lo shopping',
        },
        goToWishlist: {
          label: 'Vai alla wishlist',
        },
      },
      productAdded: 'Il prodotto selezionato è stato aggiunto alla wishlist.',
      productRemoved: 'Il prodotto selezionato è stato rimosso dalla wishlist.',
      title: 'Aggiornamento wishlist',
    },
  },
  yup: {
    array: {
      length: 'Deve contenere {{length}} items',
      max: 'Deve contenere un numero minore o uguale a {{max}} items',
      min: 'Deve contenere almeno {{min}} items',
    },
    boolean: {
      isValue: 'Deve essere {{value}}',
    },
    custom: {
      passwordConfirmationNotMatch: 'La conferma password non è corretta',
    },
    date: {
      max: 'Deve essere precedente a {{max}}',
      min: 'Deve essere successivo a {{min}}',
    },
    mixed: {
      default: 'Campo non valido',
      defined: 'Campo non definito',
      notOneOf: 'Non deve essere uno tra i seguenti valori: {{values}}',
      notType: 'Deve essere di tipo `{{type}}`, ma il valore finale era: {{value}}',
      oneOf: 'Deve essere uno tra i seguenti valori: {{values}}',
      required: 'Campo obbligatorio',
    },
    number: {
      integer: 'Deve essere un intero',
      lessThan: 'Deve essere minore di {{less}}',
      max: 'Deve essere minore o uguale a  {{max}}',
      min: 'Deve essere più grande o uguale a {{min}}',
      moreThan: 'Deve essere maggiore di {{more}}',
      negative: 'Deve essere un numero negativo',
      positive: 'Deve essere un numero positivo',
    },
    object: {
      noUnknown: 'Il campo ha chiavi non specificate: {{unknown}}',
    },
    string: {
      email: 'Formato email non valido',
      length: 'Deve avere esattamente {{length}} caratteri',
      lowercase: 'Deve essere una stringa minuscola',
      matches: 'Deve coincidere con : "{{regex}}"',
      max: 'Deve avere al massimo {{max}} caratteri',
      min: 'Deve avere minimo {{min}} caratteri',
      trim: 'Non deve contenere spazi',
      uppercase: 'Deve essere una stringa maiuscola',
      url: 'Deve essere una URL valida',
      uuid: 'Deve essere un UUID valido',
    },
  },
};
