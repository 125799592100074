import {
  ContentApiDto,
  CustomerApiDto,
  CustomerBusinessApiType,
  CustomerProfileApiType,
  CustomerRequestApiDto,
  DocumentApiType,
  DocumentAuthorityApiType,
  SexApiType,
} from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { useSessionApi } from '../api/useSessionApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { PackedModalProps, UseFormModalProps } from '../Modal';
import { useNavigate } from '../router/useNavigate';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { formatDateForInput, formatHtml, fromCustomerApiDtoToCustomerRequestApiDto, useStable } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { CustomerFormA } from './CustomerFormA';
import { CustomerFormB } from './CustomerFormB';
import { CustomerFormC } from './CustomerFormC';
import { FieldArray, FieldArrayProps } from './FieldArray';
import {
  DateInput,
  DateInputProps,
  PasswordInput,
  PasswordInputProps,
  TextInput,
  TextInputProps,
} from './fields/Input';
import { Checkbox, CheckboxProps, Radios, RadiosProps } from './fields/RadioCheck';
import { Select, SelectProps } from './fields/Select';
import {
  FormButtonProps,
  formikBoolean,
  formikBooleanAsString,
  formikDate,
  formikEnum,
  FormikHelpers,
  formikString,
  getFormikBooleanValue,
  getFormikDateValue,
  getFormikEnumValue,
  getFormikStringValue,
  getInitialBoolean,
  getInitialBooleanAsString,
  getInitialDate,
  getInitialEnum,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

type Mode = 'registration' | 'update' | 'updateRequiredFields';

export interface CustomerFormProps<
  AdditionalPropertiesFormValues,
  AdditionalPropertiesValidationSchema,
  CustomPropertiesFormValues,
  CustomPropertiesValidationSchema,
  AdditionalPropertiesChildrenFormValues,
  AdditionalPropertiesChildrenValidationSchema,
  CustomPropertiesChildrenFormValues,
  CustomPropertiesChildrenValidationSchema
> extends BaseHelpedFormProps<
    FormValues<
      AdditionalPropertiesFormValues,
      CustomPropertiesFormValues,
      AdditionalPropertiesChildrenFormValues,
      CustomPropertiesChildrenFormValues
    >,
    FieldsHelper<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>,
    ValidationSchemaSelector,
    { content?: ContentApiDto<ConsentsContentType>; mode: Mode }
  > {
  additionalProperties?: {
    formValues: Record<
      string,
      (
        values: FormValues<
          AdditionalPropertiesFormValues,
          CustomPropertiesFormValues,
          AdditionalPropertiesChildrenFormValues,
          CustomPropertiesChildrenFormValues
        >
      ) => string
    >;
    initialValues: AdditionalPropertiesFormValues;
    validationSchema: AdditionalPropertiesValidationSchema;
  };
  additionalPropertiesChildren?: {
    formValues: Record<
      string,
      (values: ChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>) => string
    >;
    initialValues: AdditionalPropertiesChildrenFormValues;
    validationSchema: AdditionalPropertiesChildrenValidationSchema;
  };
  customProperties?: {
    initialValues: CustomPropertiesFormValues;
    validationSchema: CustomPropertiesValidationSchema;
  };
  customPropertiesChildren?: {
    initialValues: CustomPropertiesChildrenFormValues;
    validationSchema: CustomPropertiesChildrenValidationSchema;
  };
  customer?: CustomerApiDto;
  enableNewsletterRegistration?: boolean;
  maxChildren?: number;
  maxPhoneNumbers?: number;
  minAge?: number;
  minChildren?: number;
  minChildrenAge?: number;
  minPhoneNumbers?: number;
  onCancel?(): void;
  referralCode?: string;
  successfulRegistrationModalProps?: Partial<Omit<PackedModalProps, 'id'>>;
  updateRequiredFields?: {
    checkCodes: Array<string>;
    token: string;
  };
}

interface FormValues<
  AdditionalPropertiesFormValues,
  CustomPropertiesFormValues,
  AdditionalPropertiesChildrenFormValues,
  CustomPropertiesChildrenFormValues
> {
  additionalProperties?: AdditionalPropertiesFormValues;
  birthdate: formikDate;
  businessType: formikEnum<CustomerBusinessApiType>;
  children: Array<ChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>>;
  company: formikString;
  customProperties?: CustomPropertiesFormValues;
  document: DocumentFormValues;
  email: formikString;
  enableBusinessData: formikBooleanAsString;
  enableChildren: formikBooleanAsString;
  enableDocument: formikBooleanAsString;
  marketingConsent: formikBoolean;
  name: formikString;
  newsletterConsent: formikBoolean;
  password: formikString;
  pec: formikString;
  phoneNumbers: Array<formikString>;
  privacyConsent: formikBoolean;
  profile: formikEnum<CustomerProfileApiType>;
  profilingConsent: formikBoolean;
  sex: formikEnum<SexApiType>;
  surname: formikString;
  taxCode: formikString;
  uniqueCode: formikString;
  vatNumber: formikString;
}

export type CustomerFormValues<
  AdditionalPropertiesFormValues,
  CustomPropertiesFormValues,
  AdditionalPropertiesChildrenFormValues,
  CustomPropertiesChildrenFormValues
> = FormValues<
  AdditionalPropertiesFormValues,
  CustomPropertiesFormValues,
  AdditionalPropertiesChildrenFormValues,
  CustomPropertiesChildrenFormValues
>;

interface ChildrenFormValues<AdditionalPropertiesFormValues, CustomPropertiesFormValues> {
  additionalProperties?: AdditionalPropertiesFormValues;
  birthdate: formikDate;
  customProperties?: CustomPropertiesFormValues;
  id: formikString;
  name: formikString;
  sex: formikEnum<SexApiType>;
  surname: formikString;
}

export type CustomerChildrenFormValues<AdditionalPropertiesFormValues, CustomPropertiesFormValues> = ChildrenFormValues<
  AdditionalPropertiesFormValues,
  CustomPropertiesFormValues
>;

interface DocumentFormValues {
  authorityDescription: formikString;
  authorityType: formikEnum<DocumentAuthorityApiType>;
  districtName: formikString;
  districtType: formikString;
  documentNumber: formikString;
  documentType: formikEnum<DocumentApiType>;
  id: formikString;
  releaseDate: formikDate;
}

type ValidationSchema<
  AdditionalPropertiesValidationSchema,
  CustomPropertiesValidationSchema,
  AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
> = {
  additionalProperties?: AdditionalPropertiesValidationSchema;
  birthdate: yup.DateSchema;
  businessType: yup.StringSchema;
  children: yup.ArraySchema<
    yup.ObjectSchema<{
      additionalProperties: AdditionalPropertiesChildrenValidationSchema;
      birthdate: yup.DateSchema;
      customProperties: CustomPropertiesChildrenValidationSchema;
      name: yup.StringSchema;
      sex: yup.StringSchema;
      surname: yup.StringSchema;
    }>
  >;
  company: yup.StringSchema;
  customProperties?: CustomPropertiesValidationSchema;
  document: yup.ObjectSchema<{
    authorityDescription: yup.StringSchema;
    authorityType: yup.StringSchema;
    districtName: yup.StringSchema;
    districtType: yup.StringSchema;
    documentNumber: yup.StringSchema;
    documentType: yup.StringSchema;
    releaseDate: yup.DateSchema;
  }>;
  email: yup.StringSchema;
  enableBusinessData: yup.StringSchema;
  enableChildren: yup.StringSchema;
  enableDocument: yup.StringSchema;
  marketingConsent: yup.BooleanSchema;
  name: yup.StringSchema;
  newsletterConsent: yup.BooleanSchema;
  password: yup.StringSchema;
  pec: yup.StringSchema;
  phoneNumbers: yup.ArraySchema<yup.StringSchema>;
  privacyConsent: yup.BooleanSchema;
  profile: yup.StringSchema;
  profilingConsent: yup.BooleanSchema;
  sex: yup.StringSchema;
  surname: yup.StringSchema;
  taxCode: yup.StringSchema;
  uniqueCode: yup.StringSchema;
  vatNumber: yup.StringSchema;
};

interface ValidationSchemaSelector {
  birthdate: boolean;
  children: {
    birthdate: boolean;
    name: boolean;
    sex: boolean;
    surname: boolean;
  };
  name: boolean;
  phoneNumber: boolean;
  profile: boolean;
  sex: boolean;
  surname: boolean;
}

interface FieldsHelper<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues> {
  birthdate: { dateInput: DateInputProps; formGroup: FormGroupProps };
  businessType: { formGroup: FormGroupProps; radios: RadiosProps; select: SelectProps };
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  children: {
    addButton: FormButtonProps;
    fieldArray: Omit<
      FieldArrayProps<ChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>>,
      'children'
    >;
    fields: Array<{
      birthdate: { dateInput: DateInputProps; formGroup: FormGroupProps };
      name: { formGroup: FormGroupProps; textInput: TextInputProps };
      removeButton: FormButtonProps;
      sex: { formGroup: FormGroupProps; select: SelectProps };
      surname: { formGroup: FormGroupProps; textInput: TextInputProps };
    }>;
    formGroup: FormGroupProps;
  };
  company: { formGroup: FormGroupProps; textInput: TextInputProps };
  document: {
    fields: {
      authorityDescription: { formGroup: FormGroupProps; textInput: TextInputProps };
      authorityType: { formGroup: FormGroupProps; select: SelectProps };
      districtName: { formGroup: FormGroupProps; textInput: TextInputProps };
      districtType: { formGroup: FormGroupProps; textInput: TextInputProps };
      documentNumber: { formGroup: FormGroupProps; textInput: TextInputProps };
      documentType: { formGroup: FormGroupProps; select: SelectProps };
      releaseDate: { dateInput: DateInputProps; formGroup: FormGroupProps };
    };
    formGroup: FormGroupProps;
  };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
  enableBusinessData: {
    formGroup: FormGroupProps;
    radios: RadiosProps;
  };
  enableChildren: {
    formGroup: FormGroupProps;
    radios: RadiosProps;
  };
  enableDocument: {
    formGroup: FormGroupProps;
    radios: RadiosProps;
  };
  marketingConsent: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  name: { formGroup: FormGroupProps; textInput: TextInputProps };
  newsletterConsent: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  password: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  pec: { formGroup: FormGroupProps; textInput: TextInputProps };
  phoneNumbers: {
    addButton: FormButtonProps;
    fieldArray: Omit<FieldArrayProps<formikString>, 'children'>;
    fields: Array<{
      formGroup: FormGroupProps;
      removeButton: FormButtonProps;
      textInput: TextInputProps;
    }>;
    formGroup: FormGroupProps;
  };
  privacyConsent: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  profile: { formGroup: FormGroupProps; radios: RadiosProps; select: SelectProps };
  profilingConsent: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  sex: { formGroup: FormGroupProps; select: SelectProps };
  surname: { formGroup: FormGroupProps; textInput: TextInputProps };
  taxCode: { formGroup: FormGroupProps; textInput: TextInputProps };
  uniqueCode: { formGroup: FormGroupProps; textInput: TextInputProps };
  vatNumber: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export type CustomerFormFieldsHelper<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues> =
  FieldsHelper<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>;

export const CustomerFormHelper = <
  AdditionalPropertiesFormValues extends Record<string, unknown>,
  AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesFormValues extends Record<string, unknown>,
  CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
  AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
  AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesChildrenFormValues extends Record<string, unknown>,
  CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
>({
  additionalProperties,
  additionalPropertiesChildren,
  children,
  className,
  customProperties,
  customPropertiesChildren,
  customer,
  initialValues,
  maxChildren = 10,
  maxPhoneNumbers = 2,
  minAge = 0,
  minChildren = 0,
  minChildrenAge = 0,
  minPhoneNumbers = 1,
  onCancel,
  onSuccess,
  referralCode,
  successfulRegistrationModalProps,
  updateRequiredFields,
  validationSchemaSelector = {
    birthdate: true,
    children: { birthdate: true, name: true, sex: true, surname: true },
    name: true,
    phoneNumber: true,
    profile: true,
    sex: true,
    surname: true,
  },
  ...otherProps
}: CustomerFormProps<
  AdditionalPropertiesFormValues,
  AdditionalPropertiesValidationSchema,
  CustomPropertiesFormValues,
  CustomPropertiesValidationSchema,
  AdditionalPropertiesChildrenFormValues,
  AdditionalPropertiesChildrenValidationSchema,
  CustomPropertiesChildrenFormValues,
  CustomPropertiesChildrenValidationSchema
>) => {
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const mode: Mode =
    customer?.email === undefined
      ? 'registration'
      : updateRequiredFields !== undefined
      ? 'updateRequiredFields'
      : 'update';

  console.log('mode', mode);

  const customerAdditionalProperties = useStable(customer?.additionalPropertiesExt);

  const _initialValues = React.useMemo<
    FormValues<
      AdditionalPropertiesFormValues,
      CustomPropertiesFormValues,
      AdditionalPropertiesChildrenFormValues,
      CustomPropertiesChildrenFormValues
    >
  >(
    () => ({
      additionalProperties:
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        (_.mapValues(customerAdditionalProperties, (additionalProperty) => additionalProperty?.value) as any) ??
        additionalProperties?.initialValues,
      birthdate: getInitialDate(customer?.birthdate),
      businessType: getInitialEnum(customer?.businessType),
      children: customer?.children
        ? customer.children.map((child) => ({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            additionalProperties: (child.additionalProperties as any) ?? additionalPropertiesChildren?.initialValues,
            birthdate: getInitialDate(child.birthdate),
            customProperties: customPropertiesChildren?.initialValues,
            id: getInitialString(child.id),
            name: getInitialString(child.name),
            sex: getInitialEnum(child.sex),
            surname: getInitialString(child.surname),
          }))
        : [],
      company: getInitialString(customer?.company),
      customProperties: customProperties?.initialValues,
      document: {
        authorityDescription: getInitialString(customer?.document?.authorityDescription),
        authorityType: getInitialEnum(customer?.document?.authorityType),
        districtName: getInitialString(customer?.document?.districtName),
        districtType: getInitialString(customer?.document?.districtType),
        documentNumber: getInitialString(customer?.document?.documentNumber),
        documentType: getInitialEnum(customer?.document?.documentType),
        id: getInitialString(customer?.document?.id ?? '0'),
        releaseDate: getInitialDate(customer?.document?.releaseDate),
      },
      email: getInitialString(customer?.email),
      enableBusinessData: getInitialBooleanAsString(customer?.profile === 'BUSINESS' ? true : false),
      enableChildren: getInitialBooleanAsString(customer?.children && customer.children.length > 0 ? true : false),
      enableDocument: getInitialBooleanAsString(customer?.document ? true : false),
      marketingConsent: getInitialBoolean(customer?.marketingConsent),
      name: getInitialString(customer?.name),
      newsletterConsent: getInitialBoolean(customer?.newsletterConsent),
      password: getInitialString(),
      pec: getInitialString(customer?.pec),
      phoneNumbers: [
        ...Array(
          customer?.phoneNumbers
            ? customer.phoneNumbers.length > minPhoneNumbers
              ? customer.phoneNumbers.length
              : minPhoneNumbers
            : minPhoneNumbers
        ),
      ].map((phoneNumber, index) => getInitialString(customer?.phoneNumbers && customer.phoneNumbers[index])),
      privacyConsent: getInitialBoolean(),
      profile: getInitialEnum(customer?.profile ?? 'PRIVATE'),
      profilingConsent: getInitialBoolean(customer?.profilingConsent),
      sex: getInitialEnum(customer?.sex),
      surname: getInitialString(customer?.surname),
      taxCode: getInitialString(customer?.taxCode),
      uniqueCode: getInitialString(customer?.uniqueCode),
      vatNumber: getInitialString(customer?.vatNumber),
      ...initialValues,
    }),
    [
      customerAdditionalProperties,
      additionalProperties?.initialValues,
      customer?.birthdate,
      customer?.businessType,
      customer?.children,
      customer?.company,
      customer?.document,
      customer?.email,
      customer?.marketingConsent,
      customer?.name,
      customer?.newsletterConsent,
      customer?.pec,
      customer?.phoneNumbers,
      customer?.profile,
      customer?.profilingConsent,
      customer?.sex,
      customer?.surname,
      customer?.taxCode,
      customer?.uniqueCode,
      customer?.vatNumber,
      customProperties?.initialValues,
      minPhoneNumbers,
      initialValues,
      additionalPropertiesChildren?.initialValues,
      customPropertiesChildren?.initialValues,
    ]
  );

  const maxBirthdateDate = React.useMemo(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return minAge > 0
      ? new Date(currentDate.setFullYear(currentDate.getFullYear() - minAge))
      : new Date(currentDate.setDate(currentDate.getDate() - 1));
  }, [minAge]);

  const maxChildrenBirthdateDate = React.useMemo(() => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return minChildrenAge > 0
      ? new Date(currentDate.setFullYear(currentDate.getFullYear() - minChildrenAge))
      : new Date(currentDate.setDate(currentDate.getDate() - 1));
  }, [minChildrenAge]);

  const _minPhoneNumbers = validationSchemaSelector.phoneNumber
    ? minPhoneNumbers && minPhoneNumbers > 0
      ? minPhoneNumbers
      : 1
    : minPhoneNumbers;
  const _maxPhoneNumbers = maxPhoneNumbers && maxPhoneNumbers > _minPhoneNumbers ? maxPhoneNumbers : _minPhoneNumbers;

  const validationSchema = React.useMemo<
    ValidationSchema<
      AdditionalPropertiesValidationSchema,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesValidationSchema
    >
  >(
    () => ({
      additionalProperties: additionalProperties?.validationSchema,
      birthdate: validationSchemaSelector.birthdate
        ? yup
            .date()
            .max(
              maxBirthdateDate,
              minAge > 0
                ? t('form.customerForm.birthdate.errors.minAgeWithDate', { count: minAge })
                : t('form.customerForm.birthdate.errors.minAge')
            )
            .required()
        : yup
            .date()
            .max(
              maxBirthdateDate,
              minAge > 0
                ? t('form.customerForm.birthdate.errors.minAgeWithDate', { count: minAge })
                : t('form.customerForm.birthdate.errors.minAge')
            ),
      // businessType: validationSchemaSelector.businessType
      //   ? yup.string().required()
      //   : yup.string().when('profile', {
      //     is: 'BUSINESS',
      //     then: (schema) => schema.required(),
      //   }),
      businessType: yup.string().when('enableBusinessData', {
        is: 'true',
        then: (schema) =>
          schema.when('profile', {
            is: 'BUSINESS',
            then: () => schema.required(),
          }),
      }),
      children: yup.array().when(
        'enableChildren',
        {
          is: 'true',
          otherwise: yup.array(),
          then: yup.array().of(
            yup.object().shape({
              additionalProperties: additionalPropertiesChildren
                ? additionalPropertiesChildren.validationSchema
                : yup.object(),
              birthdate: validationSchemaSelector.children.birthdate
                ? yup
                    .date()
                    .max(
                      maxChildrenBirthdateDate,
                      minChildrenAge > 0
                        ? t('form.customerForm.children.birthdate.errors.minAgeWithDate', { count: minChildrenAge })
                        : t('form.customerForm.children.birthdate.errors.minAge')
                    )
                    .required()
                : yup
                    .date()
                    .max(
                      maxChildrenBirthdateDate,
                      minChildrenAge > 0
                        ? t('form.customerForm.children.birthdate.errors.minAgeWithDate', { count: minChildrenAge })
                        : t('form.customerForm.children.birthdate.errors.minAge')
                    ),
              customProperties: customPropertiesChildren ? customPropertiesChildren.validationSchema : yup.object(),
              name: validationSchemaSelector.children.name ? yup.string().required() : yup.string(),
              sex: validationSchemaSelector.children.sex ? yup.string().required() : yup.string(),
              surname: validationSchemaSelector.children.surname ? yup.string().required() : yup.string(),
            })
          ),
        }
        //FIXME Capire questo errore
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any,
      // company: validationSchemaSelector.company
      //   ? yup.string().when('businessType', {
      //       is: 'COMPANY',
      //       then: (schema) => schema.required(),
      //     })
      //   : yup.string(),
      company: yup.string().when('enableBusinessData', {
        is: 'true',
        then: (schema) =>
          schema.when('businessType', {
            is: 'COMPANY',
            then: () => schema.required(),
          }),
      }),
      customProperties: customProperties?.validationSchema,
      document: yup
        .object({
          authorityDescription: yup.string(),
          authorityType: yup.string(),
          districtName: yup.string(),
          districtType: yup.string(),
          documentNumber: yup.string(),
          documentType: yup.string(),
          releaseDate: yup.date(),
        })
        .when('enableDocument', {
          is: 'true',
          then: (schema) =>
            schema.shape({
              authorityDescription: yup.string().required(),
              authorityType: yup.string().required(),
              districtName: yup.string().required(),
              districtType: yup.string().required(),
              documentNumber: yup.string().required(),
              documentType: yup.string().required(),
              releaseDate: yup.date().required(),
            }),
        }),
      email: yup.string().required().email(),
      enableBusinessData: yup.string().required(),
      enableChildren: yup.string().required(),
      enableDocument: yup.string().required(),
      marketingConsent:
        consentsContent?.body.marketing?.enabled && consentsContent.body.marketing.required
          ? yup.boolean().required().oneOf([true])
          : yup.boolean(),
      name: validationSchemaSelector.name ? yup.string().required() : yup.string(),
      newsletterConsent:
        consentsContent?.body.newsletter?.enabled && consentsContent.body.newsletter.required
          ? yup.boolean().required().oneOf([true])
          : yup.boolean(),
      password: mode === 'registration' ? yup.string().required() : yup.string(),
      // pec: yup.string().when('profile', {
      //   is: 'BUSINESS',
      //   then: (schema) => schema.required(),
      // }),
      pec: yup.string().when('enableBusinessData', {
        is: 'true',
        then: (schema) =>
          schema.when('profile', {
            is: 'BUSINESS',
            then: () => schema.required(),
          }),
      }),
      phoneNumbers: validationSchemaSelector.phoneNumber
        ? yup.array().min(_minPhoneNumbers).max(_maxPhoneNumbers).of(yup.string().required()).required()
        : yup.array().max(_maxPhoneNumbers).of(yup.string()),
      privacyConsent:
        mode === 'registration' || mode === 'updateRequiredFields'
          ? yup.boolean().required().oneOf([true])
          : yup.boolean(),
      profile: validationSchemaSelector.profile ? yup.string().required() : yup.string(),
      profilingConsent:
        consentsContent?.body.profiling?.enabled && consentsContent.body.profiling.required
          ? yup.boolean().required().oneOf([true])
          : yup.boolean(),
      sex: validationSchemaSelector.sex ? yup.string().required() : yup.string(),
      surname: validationSchemaSelector.surname ? yup.string().required() : yup.string(),
      // taxCode: validationSchemaSelector.taxCode
      //   ? yup.string().when('businessType', {
      //       is: 'PROPRIETORSHIP',
      //       then: (schema) => schema.required(),
      //     })
      //   : yup.string(),
      // uniqueCode: yup.string().when('profile', {
      //   is: 'BUSINESS',
      //   then: (schema) => schema.required(),
      // }),
      // vatNumber: yup.string().when('profile', {
      //   is: 'BUSINESS',
      //   then: (schema) => schema.required(),
      // }),
      taxCode: yup.string().when('enableBusinessData', {
        is: 'true',
        then: (schema) =>
          schema.when('businessType', {
            is: 'PROPRIETORSHIP',
            then: () => schema.required(),
          }),
      }),
      uniqueCode: yup.string().when('enableBusinessData', {
        is: 'true',
        then: (schema) =>
          schema.when('profile', {
            is: 'BUSINESS',
            then: () => schema.required(),
          }),
      }),
      vatNumber: yup.string().when('enableBusinessData', {
        is: 'true',
        then: (schema) =>
          schema.when('profile', {
            is: 'BUSINESS',
            then: () => schema.required(),
          }),
      }),
    }),
    [
      additionalProperties?.validationSchema,
      validationSchemaSelector.birthdate,
      validationSchemaSelector.children.birthdate,
      validationSchemaSelector.children.name,
      validationSchemaSelector.children.sex,
      validationSchemaSelector.children.surname,
      validationSchemaSelector.name,
      validationSchemaSelector.phoneNumber,
      validationSchemaSelector.profile,
      validationSchemaSelector.sex,
      validationSchemaSelector.surname,
      maxBirthdateDate,
      minAge,
      additionalPropertiesChildren,
      maxChildrenBirthdateDate,
      minChildrenAge,
      customPropertiesChildren,
      customProperties?.validationSchema,
      consentsContent?.body.marketing?.enabled,
      consentsContent?.body.marketing?.required,
      consentsContent?.body.newsletter?.enabled,
      consentsContent?.body.newsletter?.required,
      consentsContent?.body.profiling?.enabled,
      consentsContent?.body.profiling?.required,
      mode,
      _minPhoneNumbers,
      _maxPhoneNumbers,
    ]
  );

  const { register, updateCustomer } = useCustomerApi();
  const { updateCustomerRequiredFields } = useSessionApi();

  const navigate = useNavigate();
  const { showModal } = useModals();
  const successfulRegistration = React.useCallback(() => {
    showModal({
      children: t('misc.customerForm.registrationSuccessful.modal.body'),
      fullscreen: 'md',
      onClose: () => navigate('/', { preferFrom: true }),
      size: 'default',
      title: t('misc.customerForm.registrationSuccessful.modal.title'),
      ...successfulRegistrationModalProps,
    });
  }, [showModal, navigate, successfulRegistrationModalProps]);

  const handleSubmit = React.useCallback(
    (
      values: FormValues<
        AdditionalPropertiesFormValues,
        CustomPropertiesFormValues,
        AdditionalPropertiesChildrenFormValues,
        CustomPropertiesChildrenFormValues
      >,
      formikHelpers: FormikHelpers<
        FormValues<
          AdditionalPropertiesFormValues,
          CustomPropertiesFormValues,
          AdditionalPropertiesChildrenFormValues,
          CustomPropertiesChildrenFormValues
        >
      >
    ) => {
      const customerToSubmit: CustomerRequestApiDto = {
        ...(customer ? fromCustomerApiDtoToCustomerRequestApiDto(customer) : {}),
        additionalProperties: additionalProperties?.formValues
          ? Object.entries(additionalProperties.formValues).reduce<Record<string, string>>((acc, [key, transform]) => {
              acc[key] = transform(values);
              return acc;
            }, {})
          : undefined,
        birthdate: getFormikDateValue(values.birthdate),
        businessType: values.enableBusinessData === 'true' ? getFormikEnumValue(values.businessType) : undefined,
        children:
          values.enableChildren === 'true'
            ? values.children.map((child) => ({
                additionalProperties: additionalPropertiesChildren?.formValues
                  ? Object.entries(additionalPropertiesChildren.formValues).reduce<Record<string, string>>(
                      (acc, [key, transform]) => {
                        // FIXME
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        acc[key] = transform(child as any);
                        return acc;
                      },
                      {}
                    )
                  : undefined,
                birthdate: getFormikDateValue(child.birthdate),
                id: getFormikStringValue(child.id),
                name: getFormikStringValue(child.name),
                sex: getFormikEnumValue(child.sex),
                surname: getFormikStringValue(child.surname),
              }))
            : undefined,
        company: values.enableBusinessData === 'true' ? getFormikStringValue(values.company) : undefined,
        document:
          values.enableDocument === 'true' && values.document.authorityType && values.document.documentType
            ? {
                authorityDescription: getFormikStringValue(values.document.authorityDescription),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                authorityType: getFormikEnumValue(values.document.authorityType)!,
                districtName: getFormikStringValue(values.document.districtName),
                districtType: getFormikStringValue(values.document.districtType),
                documentNumber: getFormikStringValue(values.document.documentNumber),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                documentType: getFormikEnumValue(values.document.documentType)!,
                id: getFormikStringValue(values.document.id),
                releaseDate: getFormikDateValue(values.document.releaseDate),
              }
            : undefined,
        email: getFormikStringValue(values.email),
        id: customer?.id ?? '0',
        marketingConsent: getFormikBooleanValue(values.marketingConsent),
        name: getFormikStringValue(values.name),
        newsletterConsent: getFormikBooleanValue(values.newsletterConsent),
        pec: values.enableBusinessData === 'true' ? getFormikStringValue(values.pec) : undefined,
        phoneNumbers: values.phoneNumbers.map((phoneNumber) => getFormikStringValue(phoneNumber)),
        profile: getFormikEnumValue(values.profile),
        profilingConsent: getFormikBooleanValue(values.profilingConsent),
        sex: getFormikEnumValue(values.sex),
        surname: getFormikStringValue(values.surname),
        taxCode: values.enableBusinessData === 'true' ? getFormikStringValue(values.taxCode) : undefined,
        uniqueCode: values.enableBusinessData === 'true' ? getFormikStringValue(values.uniqueCode) : undefined,
        vatNumber: values.enableBusinessData === 'true' ? getFormikStringValue(values.vatNumber) : undefined,
        verified: mode === 'registration' ? false : undefined,
      };

      if (mode === 'registration') {
        return register({
          customer: customerToSubmit,
          friendToken: referralCode,
          password: getFormikStringValue(values.password),
        }).then(() => {
          onSuccess ? onSuccess() : successfulRegistration();
        });
      } else if (mode === 'updateRequiredFields' && updateRequiredFields !== undefined) {
        return updateCustomerRequiredFields(
          {
            checkCodes: updateRequiredFields.checkCodes,
            customerRequestApiDto: customerToSubmit,
            token: updateRequiredFields.token,
          },
          { populate: { children: { additionalProperties: true } } }
        ).then(() => {
          onSuccess && onSuccess();
        });
      } else {
        return updateCustomer(customerToSubmit, {
          populate: {
            children: { additionalProperties: true },
          },
        }).then(() => {
          onSuccess && onSuccess();
        });
      }
    },
    [
      customer,
      additionalProperties?.formValues,
      mode,
      updateRequiredFields,
      additionalPropertiesChildren?.formValues,
      register,
      onSuccess,
      updateCustomerRequiredFields,
      updateCustomer,
      successfulRegistration,
      referralCode,
    ]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<
      FormValues<
        AdditionalPropertiesFormValues,
        CustomPropertiesFormValues,
        AdditionalPropertiesChildrenFormValues,
        CustomPropertiesChildrenFormValues
      >
    >
      className={classnames('CustomerForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues> = {
          birthdate: {
            dateInput: { max: formatDateForInput(maxBirthdateDate), min: '', name: 'birthdate', step: 1 },
            formGroup: { label: t('form.customerForm.birthdate.label'), names: ['birthdate'] },
          },
          businessType: {
            formGroup: {
              label: t('form.customerForm.businessType.label'),
              names: ['businessType'],
              required: formik.values.enableBusinessData === 'true',
            },
            radios: {
              radios: [
                {
                  id: 'company',
                  inline: true,
                  label: t('form.customerForm.businessType.options.company'),
                  name: 'businessType',
                  value: 'COMPANY',
                },
                {
                  id: 'proprietorship',
                  inline: true,
                  label: t('form.customerForm.businessType.options.proprietorship'),
                  name: 'businessType',
                  value: 'PROPRIETORSHIP',
                },
              ],
            },
            select: {
              includeEmptyOption: false,
              name: 'businessType',
              options: [
                { label: t('form.customerForm.businessType.options.proprietorship'), value: 'PROPRIETORSHIP' },
                { label: t('form.customerForm.businessType.options.company'), value: 'COMPANY' },
              ],
              placeholder: t('form.customerForm.businessType.placeholder'),
            },
          },
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.customerForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label:
                mode === 'registration'
                  ? t('form.customerForm.buttons.submit.registrationLabel')
                  : mode === 'updateRequiredFields'
                  ? t('form.customerForm.buttons.submit.updateRequiredFieldsLabel')
                  : t('form.customerForm.buttons.submit.updateLabel'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          children: {
            addButton: { label: t('form.customerForm.children.buttons.add.label') },
            fieldArray: {
              items: formik.values.children,
              max: maxChildren ? maxChildren : 0,
              min: minChildren ? minChildren : 0,
              name: 'children',
            },
            fields: formik.values.children.map((_children, index) => ({
              birthdate: {
                dateInput: {
                  max: formatDateForInput(maxChildrenBirthdateDate),
                  min: '',
                  name: `children.${index}.birthdate`,
                  step: 1,
                },
                formGroup: {
                  label: t('form.customerForm.children.birthdate.label'),
                  name: `children.${index}.birthdate`,
                  names: [`children.${index}.birthdate`],
                },
              },
              name: {
                formGroup: {
                  label: t('form.customerForm.children.name.label'),
                  name: `children.${index}.name`,
                  names: [`children.${index}.name`],
                },
                textInput: {
                  name: `children.${index}.name`,
                  placeholder: t('form.customerForm.children.name.label'),
                },
              },
              removeButton: { label: t('form.customerForm.children.buttons.remove.label') },
              sex: {
                formGroup: {
                  label: t('form.customerForm.children.sex.label'),
                  name: `children.${index}.sex`,
                  names: [`children.${index}.sex`],
                },
                select: {
                  includeEmptyOption: false,
                  name: `children.${index}.sex`,
                  options: [
                    { label: t('form.customerForm.children.sex.options.male'), value: 'M' },
                    { label: t('form.customerForm.children.sex.options.female'), value: 'F' },
                    { label: t('form.customerForm.children.sex.options.other'), value: 'O' },
                  ],
                  placeholder: t('form.customerForm.children.sex.placeholder'),
                },
              },
              surname: {
                formGroup: {
                  label: t('form.customerForm.children.surname.label'),
                  names: [`children.${index}.surname`],
                },
                textInput: {
                  name: `children.${index}.surname`,
                  placeholder: t('form.customerForm.children.surname.label'),
                },
              },
            })),
            formGroup: {
              label: t('form.customerForm.children.formGroup.label', {
                current: formik.values.children.length,
                max: maxChildren ? maxChildren : 0,
                min: minChildren ? minChildren : 0,
              }),
              names: ['children'],
            },
          },
          company: {
            formGroup: {
              label: t('form.customerForm.company.label'),
              names: ['company'],
              required: formik.values.enableBusinessData === 'true' && formik.values.businessType === 'COMPANY',
            },
            textInput: {
              // disabled: mode === 'update',
              name: 'company',
              placeholder: t('form.customerForm.company.label'),
            },
          },
          document: {
            fields: {
              authorityDescription: {
                formGroup: {
                  label: t('form.customerForm.document.authorityDescription.label'),
                  names: ['document.authorityDescription'],
                },
                textInput: {
                  name: 'document.authorityDescription',
                  placeholder: t('form.customerForm.document.authorityDescription.label'),
                },
              },
              authorityType: {
                formGroup: {
                  label: t('form.customerForm.document.authorityType.label'),
                  names: ['document.authorityType'],
                },
                select: {
                  includeEmptyOption: false,
                  name: 'document.authorityType',
                  options: [
                    {
                      label: t('form.customerForm.document.authorityType.options.municipality'),
                      value: 'MUNICIPALITY',
                    },
                    {
                      label: t('form.customerForm.document.authorityType.options.prefecture'),
                      value: 'PREFECTURE',
                    },
                    { label: t('form.customerForm.document.authorityType.options.consulate'), value: 'CONSULATE' },
                    {
                      label: t('form.customerForm.document.authorityType.options.financeOffice'),
                      value: 'FINANCE_OFFICE',
                    },
                    {
                      label: t('form.customerForm.document.authorityType.options.foreignState'),
                      value: 'FOREIGN_STATE',
                    },
                    {
                      label: t('form.customerForm.document.authorityType.options.motorization'),
                      value: 'MOTORIZATION',
                    },
                    { label: t('form.customerForm.document.authorityType.options.unknown'), value: 'UNKNOWN' },
                    { label: t('form.customerForm.document.authorityType.options.other'), value: 'OTHER' },
                  ],
                  placeholder: t('form.customerForm.children.sex.placeholder'),
                },
              },
              districtName: {
                formGroup: {
                  label: t('form.customerForm.document.districtName.label'),
                  names: ['document.districtName'],
                },
                textInput: {
                  name: 'document.districtName',
                  placeholder: t('form.customerForm.document.districtName.label'),
                },
              },
              districtType: {
                formGroup: {
                  label: t('form.customerForm.document.districtType.label'),
                  names: ['document.districtType'],
                },
                textInput: {
                  name: 'document.districtType',
                  placeholder: t('form.customerForm.document.districtType.label'),
                },
              },
              documentNumber: {
                formGroup: {
                  label: t('form.customerForm.document.documentNumber.label'),
                  names: ['document.documentNumber'],
                },
                textInput: {
                  name: 'document.documentNumber',
                  placeholder: t('form.customerForm.document.documentNumber.label'),
                },
              },
              documentType: {
                formGroup: {
                  label: t('form.customerForm.document.documentType.label'),
                  names: ['document.documentType'],
                },
                select: {
                  includeEmptyOption: false,
                  name: 'document.documentType',
                  options: [
                    {
                      label: t('form.customerForm.document.documentType.options.identityCard'),
                      value: 'IDENTITY_CARD',
                    },
                    { label: t('form.customerForm.document.documentType.options.passport'), value: 'PASSPORT' },
                    {
                      label: t('form.customerForm.document.documentType.options.drivingLicense'),
                      value: 'DRIVING_LICENSE',
                    },
                  ],
                  placeholder: t('form.customerForm.children.sex.placeholder'),
                },
              },
              releaseDate: {
                dateInput: { min: '', name: 'document.releaseDate', step: 1 },
                formGroup: {
                  label: t('form.customerForm.document.releaseDate.label'),
                  names: ['document.releaseDate'],
                },
              },
            },
            formGroup: {
              label: t('form.customerForm.document.label'),
              names: ['document'],
            },
          },
          email: {
            formGroup: { label: t('form.customerForm.email.label'), names: ['email'] },
            textInput: {
              disabled: mode === 'update' || mode === 'updateRequiredFields',
              name: 'email',
              placeholder: t('form.customerForm.email.placeholder'),
              type: 'email',
            },
          },
          enableBusinessData: {
            formGroup: {
              label: t('form.customerForm.enableBusinessData.formGroup.label'),
              names: ['enableBusinessData'],
            },
            radios: {
              radios: [
                {
                  id: 'enableBusinessDataYes',
                  inline: true,
                  label: t('form.customerForm.enableBusinessData.options.yes'),
                  name: 'enableBusinessData',
                  value: 'true',
                },
                {
                  id: 'enableBusinessDataNo',
                  inline: true,
                  label: t('form.customerForm.enableBusinessData.options.no'),
                  name: 'enableBusinessData',
                  value: 'false',
                },
              ],
            },
          },
          enableChildren: {
            formGroup: { label: t('form.customerForm.enableChildren.formGroup.label'), names: ['enableChildren'] },
            radios: {
              radios: [
                {
                  id: 'enableChildrenYes',
                  inline: true,
                  label: t('form.customerForm.enableChildren.options.yes'),
                  name: 'enableChildren',
                  value: 'true',
                },
                {
                  id: 'enableChildrenNo',
                  inline: true,
                  label: t('form.customerForm.enableChildren.options.no'),
                  name: 'enableChildren',
                  value: 'false',
                },
              ],
            },
          },
          enableDocument: {
            formGroup: { label: t('form.customerForm.enableDocument.formGroup.label'), names: ['enableDocument'] },
            radios: {
              radios: [
                {
                  id: 'enableDocumentYes',
                  inline: true,
                  label: t('form.customerForm.enableDocument.options.yes'),
                  name: 'enableDocument',
                  value: 'true',
                },
                {
                  id: 'enableDocumentNo',
                  inline: true,
                  label: t('form.customerForm.enableDocument.options.no'),
                  name: 'enableDocument',
                  value: 'false',
                },
              ],
            },
          },
          marketingConsent: {
            checkbox: {
              id: 'marketingConsent',
              label: formatHtml(consentsContent?.body.marketing?.label),
              name: 'marketingConsent',
            },
            formGroup: { names: ['marketingConsent'] },
          },
          name: {
            formGroup: { label: t('form.customerForm.name.label'), names: ['name'] },
            textInput: { name: 'name', placeholder: t('form.customerForm.name.placeholder') },
          },
          newsletterConsent: {
            checkbox: {
              id: 'newsletterConsent',
              label: formatHtml(consentsContent?.body.newsletter?.label),
              name: 'newsletterConsent',
            },
            formGroup: { names: ['newsletterConsent'] },
            // radios: {
            //   radios: [
            //     {
            //       id: 'newsletterConsentYes',
            //       inline: true,
            //       label: t('form.consentsForm.fields.newsletter.radios.true'),
            //       name: 'newsletterConsent',
            //       value: 'true',
            //     },
            //     {
            //       id: 'newsletterConsentNo',
            //       inline: true,
            //       label: t('form.consentsForm.fields.newsletter.radios.false'),
            //       name: 'newsletterConsent',
            //       value: 'false',
            //     },
            //   ],
            // },
          },
          password: {
            formGroup: { label: t('form.customerForm.password.label'), names: ['password'] },
            passwordInput: {
              autoComplete: 'new-password',
              disabled: mode === 'update' || mode === 'updateRequiredFields',
              name: 'password',
              placeholder: t('form.customerForm.password.placeholder'),
            },
          },
          pec: {
            formGroup: {
              label: t('form.customerForm.pec.label'),
              names: ['pec'],
              required: formik.values.enableBusinessData === 'true' && formik.values.profile === 'BUSINESS',
            },
            textInput: {
              name: 'pec',
              placeholder: t('form.customerForm.pec.label'),
            },
          },
          phoneNumbers: {
            addButton: { label: t('form.customerForm.phoneNumbers.buttons.add.label') },
            fieldArray: {
              items: formik.values.phoneNumbers,
              max: _maxPhoneNumbers,
              min: _minPhoneNumbers,
              name: 'phoneNumbers',
            },
            fields: formik.values.phoneNumbers.map((phoneNumber, index) => ({
              formGroup: { names: ['phoneNumbers'] },
              removeButton: { label: t('form.customerForm.phoneNumbers.buttons.remove.label') },
              textInput: {
                name: `phoneNumbers.${index}`,
                placeholder: t('form.customerForm.phoneNumbers.placeholder'),
                type: 'tel',
              },
            })),
            formGroup: {
              label: t('form.customerForm.phoneNumbers.label', {
                current: formik.values.phoneNumbers.length,
                max: _maxPhoneNumbers,
                min: _minPhoneNumbers,
              }),
              names: ['phoneNumbers'],
            },
          },
          privacyConsent: {
            checkbox: {
              disabled: mode === 'update',
              id: 'privacyConsent',
              label: formatHtml(consentsContent?.body.privacy),
              name: 'privacyConsent',
            },
            formGroup: { names: ['privacyConsent'] },
          },
          profile: {
            formGroup: {
              label: t('form.customerForm.profile.label'),
              names: ['profile'],
            },
            radios: {
              radios: [
                {
                  disabled: mode === 'update',
                  id: 'private',
                  inline: true,
                  label: t('form.customerForm.profile.options.private'),
                  name: 'profile',
                  value: 'PRIVATE',
                },
                {
                  disabled: mode === 'update',
                  id: 'business',
                  inline: true,
                  label: t('form.customerForm.profile.options.business'),
                  name: 'profile',
                  value: 'BUSINESS',
                },
              ],
            },
            select: {
              disabled: mode === 'update',
              includeEmptyOption: false,
              name: 'profile',
              options: [
                { label: t('form.customerForm.profile.options.private'), value: 'PRIVATE' },
                { label: t('form.customerForm.profile.options.business'), value: 'BUSINESS' },
              ],
              placeholder: t('form.customerForm.profile.placeholder'),
            },
          },
          profilingConsent: {
            checkbox: {
              id: 'profilingConsent',
              label: formatHtml(consentsContent?.body.profiling?.label),
              name: 'profilingConsent',
            },
            formGroup: { names: ['profilingConsent'] },
          },
          sex: {
            formGroup: {
              label: t('form.customerForm.sex.label'),
              names: ['sex'],
            },
            select: {
              includeEmptyOption: false,
              name: 'sex',
              options: [
                { label: t('form.customerForm.sex.options.male'), value: 'M' },
                { label: t('form.customerForm.sex.options.female'), value: 'F' },
                { label: t('form.customerForm.sex.options.other'), value: 'O' },
              ],
              placeholder: t('form.customerForm.sex.placeholder'),
            },
          },
          surname: {
            formGroup: { label: t('form.customerForm.surname.label'), names: ['surname'] },
            textInput: { name: 'surname', placeholder: t('form.customerForm.surname.placeholder') },
          },
          taxCode: {
            formGroup: {
              label: t('form.customerForm.taxCode.label'),
              names: ['taxCode'],
              required: formik.values.enableBusinessData === 'true' && formik.values.businessType === 'PROPRIETORSHIP',
            },
            textInput: {
              name: 'taxCode',
              placeholder: t('form.customerForm.taxCode.label'),
            },
          },
          uniqueCode: {
            formGroup: {
              label: t('form.customerForm.uniqueCode.label'),
              names: ['uniqueCode'],
              required: formik.values.enableBusinessData === 'true' && formik.values.profile === 'BUSINESS',
            },
            textInput: {
              name: 'uniqueCode',
              placeholder: t('form.customerForm.uniqueCode.label'),
            },
          },
          vatNumber: {
            formGroup: {
              label: t('form.customerForm.vatNumber.label'),
              names: ['vatNumber'],
              required: formik.values.enableBusinessData === 'true' && formik.values.profile === 'BUSINESS',
            },
            textInput: {
              name: 'vatNumber',
              placeholder: t('form.customerForm.vatNumber.label'),
            },
          },
        };
        return children ? (
          children({ closeModal, content: consentsContent, fieldsHelper, formik, insideModal, mode })
        ) : (
          <>
            <FormGroup {...fieldsHelper.name.formGroup}>
              <TextInput {...fieldsHelper.name.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.surname.formGroup}>
              <TextInput {...fieldsHelper.surname.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.password.formGroup}>
              <PasswordInput {...fieldsHelper.password.passwordInput} />
            </FormGroup>
            <FieldArray<string> {...fieldsHelper.phoneNumbers.fieldArray}>
              {(arrayHelpers) => (
                <FormGroup {...fieldsHelper.phoneNumbers.formGroup}>
                  {fieldsHelper.phoneNumbers.fields.map(({ formGroup, removeButton, textInput }, index) => (
                    <React.Fragment key={textInput.name}>
                      <FormGroup {...formGroup}>
                        <TextInput {...textInput} />
                      </FormGroup>
                      {_minPhoneNumbers && index >= _minPhoneNumbers && (
                        <p>
                          <Button
                            {...removeButton}
                            disabled={arrayHelpers.removeDisabled}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={() => {
                              arrayHelpers.remove(index);
                            }}
                          />
                        </p>
                      )}
                    </React.Fragment>
                  ))}
                  {_maxPhoneNumbers && formik.values.phoneNumbers.length < _maxPhoneNumbers && (
                    <p>
                      <Button
                        {...fieldsHelper.phoneNumbers.addButton}
                        disabled={arrayHelpers.pushDisabled}
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={() => {
                          arrayHelpers.push(getInitialString());
                        }}
                      />
                    </p>
                  )}
                </FormGroup>
              )}
            </FieldArray>
            <FormGroup {...fieldsHelper.birthdate.formGroup}>
              <DateInput {...fieldsHelper.birthdate.dateInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.sex.formGroup}>
              <Select {...fieldsHelper.sex.select} />
            </FormGroup>
            <FormGroup {...fieldsHelper.enableBusinessData.formGroup}>
              <Radios {...fieldsHelper.enableBusinessData.radios} />
            </FormGroup>
            <FormGroup {...fieldsHelper.taxCode.formGroup}>
              <TextInput {...fieldsHelper.taxCode.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.company.formGroup}>
              <TextInput {...fieldsHelper.company.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.pec.formGroup}>
              <TextInput {...fieldsHelper.pec.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.uniqueCode.formGroup}>
              <TextInput {...fieldsHelper.uniqueCode.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.vatNumber.formGroup}>
              <TextInput {...fieldsHelper.vatNumber.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.businessType.formGroup}>
              <Select {...fieldsHelper.businessType.select} />
            </FormGroup>
            <FormGroup {...fieldsHelper.profile.formGroup}>
              <Select {...fieldsHelper.profile.select} />
            </FormGroup>
            <FormGroup {...fieldsHelper.enableChildren.formGroup}>
              <Radios {...fieldsHelper.enableChildren.radios} />
            </FormGroup>
            {formik.values.enableChildren === 'true' && (
              <FieldArray<
                ChildrenFormValues<AdditionalPropertiesChildrenFormValues, CustomPropertiesChildrenFormValues>
              >
                {...fieldsHelper.children.fieldArray}
              >
                {(arrayHelpers) => (
                  <FormGroup {...fieldsHelper.children.formGroup}>
                    {fieldsHelper.children.fields.map(({ birthdate, name, removeButton, sex, surname }, index) => (
                      <React.Fragment key={name.textInput.name}>
                        <FormGroup {...name.formGroup}>
                          <TextInput {...name.textInput} />
                        </FormGroup>
                        <FormGroup {...surname.formGroup}>
                          <TextInput {...surname.textInput} />
                        </FormGroup>
                        <FormGroup {...birthdate.formGroup}>
                          <DateInput {...birthdate.dateInput} />
                        </FormGroup>
                        <FormGroup {...sex.formGroup}>
                          <Select {...sex.select} />
                        </FormGroup>
                        {typeof minChildren === 'number' && index >= minChildren && (
                          <p>
                            <Button
                              {...removeButton}
                              disabled={arrayHelpers.removeDisabled}
                              // eslint-disable-next-line react/jsx-no-bind
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          </p>
                        )}
                      </React.Fragment>
                    ))}
                    {maxChildren && formik.values.children.length < maxChildren && (
                      <p>
                        <Button
                          {...fieldsHelper.children.addButton}
                          disabled={arrayHelpers.pushDisabled}
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => {
                            arrayHelpers.push({
                              additionalProperties: additionalPropertiesChildren?.initialValues,
                              birthdate: getInitialDate(),
                              customProperties: customPropertiesChildren?.initialValues,
                              id: getInitialString('0'),
                              name: getInitialString(),
                              sex: getInitialEnum(),
                              surname: getInitialString(),
                            });
                          }}
                        />
                      </p>
                    )}
                  </FormGroup>
                )}
              </FieldArray>
            )}
            <FormGroup {...fieldsHelper.enableDocument.formGroup}>
              <Radios {...fieldsHelper.enableDocument.radios} />
            </FormGroup>
            {formik.values.enableDocument === 'true' && (
              <FormGroup {...fieldsHelper.document.formGroup}>
                <FormGroup {...fieldsHelper.document.fields.authorityDescription.formGroup}>
                  <TextInput {...fieldsHelper.document.fields.authorityDescription.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.document.fields.authorityType.formGroup}>
                  <Select {...fieldsHelper.document.fields.authorityType.select} />
                </FormGroup>
                <FormGroup {...fieldsHelper.document.fields.districtName.formGroup}>
                  <TextInput {...fieldsHelper.document.fields.districtName.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.document.fields.districtType.formGroup}>
                  <TextInput {...fieldsHelper.document.fields.districtType.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.document.fields.documentNumber.formGroup}>
                  <TextInput {...fieldsHelper.document.fields.documentNumber.textInput} />
                </FormGroup>
                <FormGroup {...fieldsHelper.document.fields.documentType.formGroup}>
                  <Select {...fieldsHelper.document.fields.documentType.select} />
                </FormGroup>
                <FormGroup {...fieldsHelper.document.fields.releaseDate.formGroup}>
                  <DateInput {...fieldsHelper.document.fields.releaseDate.dateInput} />
                </FormGroup>
              </FormGroup>
            )}
            <FormGroup {...fieldsHelper.privacyConsent.formGroup}>
              <Checkbox {...fieldsHelper.privacyConsent.checkbox} />
            </FormGroup>
            {consentsContent?.body.marketing?.enabled && (
              <FormGroup {...fieldsHelper.marketingConsent.formGroup}>
                <Checkbox {...fieldsHelper.marketingConsent.checkbox} />
              </FormGroup>
            )}
            {consentsContent?.body.profiling?.enabled && (
              <FormGroup {...fieldsHelper.profilingConsent.formGroup}>
                <Checkbox {...fieldsHelper.profilingConsent.checkbox} />
              </FormGroup>
            )}
            {consentsContent?.body.newsletter?.enabled && (
              <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
                <Checkbox {...fieldsHelper.newsletterConsent.checkbox} />
              </FormGroup>
            )}
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CustomerFormVariants = 'A' | 'B' | 'C';

const CustomerFormController = <
  AdditionalPropertiesFormValues extends Record<string, unknown>,
  AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesFormValues extends Record<string, unknown>,
  CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
  AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
  AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesChildrenFormValues extends Record<string, unknown>,
  CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
>(
  props: PropsWithCustomComponentWithoutChildren<
    CustomerFormProps<
      AdditionalPropertiesFormValues,
      AdditionalPropertiesValidationSchema,
      CustomPropertiesFormValues,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenFormValues,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesChildrenFormValues,
      CustomPropertiesChildrenValidationSchema
    >
  >
) => (
  <VariantsController<
    CustomerFormProps<
      AdditionalPropertiesFormValues,
      AdditionalPropertiesValidationSchema,
      CustomPropertiesFormValues,
      CustomPropertiesValidationSchema,
      AdditionalPropertiesChildrenFormValues,
      AdditionalPropertiesChildrenValidationSchema,
      CustomPropertiesChildrenFormValues,
      CustomPropertiesChildrenValidationSchema
    >,
    CustomerFormVariants
  >
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CustomerFormA, B: CustomerFormB, C: CustomerFormC },
      defaultComponent: CustomerFormHelper,
      name: 'CustomerForm',
    }}
  />
);

export { CustomerFormController as CustomerForm };

export const useCustomerFormModal = <
  AdditionalPropertiesFormValues extends Record<string, unknown>,
  AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesFormValues extends Record<string, unknown>,
  CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
  AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
  AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
  CustomPropertiesChildrenFormValues extends Record<string, unknown>,
  CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
>(
  modalProps?: UseFormModalProps
) => {
  const { showModal } = useModals();
  return React.useCallback(
    (
      formProps: CustomerFormProps<
        AdditionalPropertiesFormValues,
        AdditionalPropertiesValidationSchema,
        CustomPropertiesFormValues,
        CustomPropertiesValidationSchema,
        AdditionalPropertiesChildrenFormValues,
        AdditionalPropertiesChildrenValidationSchema,
        CustomPropertiesChildrenFormValues,
        CustomPropertiesChildrenValidationSchema
      >
    ) => {
      showModal({
        children: <CustomerFormController {...formProps} />,
        size: 'large',
        title: t('form.customerForm.modal.title'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};

// ///////////////////////////
// // PER QUESTO FORM GESTISCO 2 VARIANT CONTROLLER DIFFERENT: REGISTRATION e PERSONALDATA
// ///////////////////////////

// export type RegistrationFormVariants = 'A';

// const RegistrationFormController = <
//   AdditionalPropertiesFormValues extends Record<string, unknown>,
//   AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesFormValues extends Record<string, unknown>,
//   CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
//   AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
//   AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesChildrenFormValues extends Record<string, unknown>,
//   CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
// >(
//   props: PropsWithCustomComponentWithoutChildren<
//     CustomerFormProps<
//       AdditionalPropertiesFormValues,
//       AdditionalPropertiesValidationSchema,
//       CustomPropertiesFormValues,
//       CustomPropertiesValidationSchema,
//       AdditionalPropertiesChildrenFormValues,
//       AdditionalPropertiesChildrenValidationSchema,
//       CustomPropertiesChildrenFormValues,
//       CustomPropertiesChildrenValidationSchema
//     >
//   >
// ) => (
//   <VariantsController<
//     CustomerFormProps<
//       AdditionalPropertiesFormValues,
//       AdditionalPropertiesValidationSchema,
//       CustomPropertiesFormValues,
//       CustomPropertiesValidationSchema,
//       AdditionalPropertiesChildrenFormValues,
//       AdditionalPropertiesChildrenValidationSchema,
//       CustomPropertiesChildrenFormValues,
//       CustomPropertiesChildrenValidationSchema
//     >,
//     RegistrationFormVariants
//   >
//     {...props}
//     variantsControllerConfig={{
//       componentVariants: { A: RegistrationFormA },
//       defaultComponent: CustomerFormHelper,
//       name: 'RegistrationForm',
//     }}
//   />
// );

// export { RegistrationFormController as RegistrationForm };

// export const useRegistrationFormModal = <
//   AdditionalPropertiesFormValues extends Record<string, unknown>,
//   AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesFormValues extends Record<string, unknown>,
//   CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
//   AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
//   AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesChildrenFormValues extends Record<string, unknown>,
//   CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
// >(
//   modalProps?: UseFormModalProps
// ) => {
//   const { showModal } = useModals();
//   return React.useCallback(
//     (
//       formProps: CustomerFormProps<
//         AdditionalPropertiesFormValues,
//         AdditionalPropertiesValidationSchema,
//         CustomPropertiesFormValues,
//         CustomPropertiesValidationSchema,
//         AdditionalPropertiesChildrenFormValues,
//         AdditionalPropertiesChildrenValidationSchema,
//         CustomPropertiesChildrenFormValues,
//         CustomPropertiesChildrenValidationSchema
//       >
//     ) => {
//       showModal({
//         children: <RegistrationFormController {...formProps} />,
//         size: 'large',
//         title: t('form.customerForm.modal.title'),
//         ...modalProps,
//       });
//     },
//     [modalProps, showModal]
//   );
// };

// //

// export type PersonalDataFormVariants = 'A';

// const PersonalDataFormController = <
//   AdditionalPropertiesFormValues extends Record<string, unknown>,
//   AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesFormValues extends Record<string, unknown>,
//   CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
//   AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
//   AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesChildrenFormValues extends Record<string, unknown>,
//   CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
// >(
//   props: PropsWithCustomComponentWithoutChildren<
//     CustomerFormProps<
//       AdditionalPropertiesFormValues,
//       AdditionalPropertiesValidationSchema,
//       CustomPropertiesFormValues,
//       CustomPropertiesValidationSchema,
//       AdditionalPropertiesChildrenFormValues,
//       AdditionalPropertiesChildrenValidationSchema,
//       CustomPropertiesChildrenFormValues,
//       CustomPropertiesChildrenValidationSchema
//     >
//   >
// ) => (
//   <VariantsController<
//     CustomerFormProps<
//       AdditionalPropertiesFormValues,
//       AdditionalPropertiesValidationSchema,
//       CustomPropertiesFormValues,
//       CustomPropertiesValidationSchema,
//       AdditionalPropertiesChildrenFormValues,
//       AdditionalPropertiesChildrenValidationSchema,
//       CustomPropertiesChildrenFormValues,
//       CustomPropertiesChildrenValidationSchema
//     >,
//     PersonalDataFormVariants
//   >
//     {...props}
//     variantsControllerConfig={{
//       componentVariants: { A: PersonalDataFormA },
//       defaultComponent: CustomerFormHelper,
//       name: 'PersonalDataForm',
//     }}
//   />
// );

// export { PersonalDataFormController as PersonalDataForm };

// export const usePersonalDataFormModal = <
//   AdditionalPropertiesFormValues extends Record<string, unknown>,
//   AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesFormValues extends Record<string, unknown>,
//   CustomPropertiesValidationSchema extends yup.AnyObjectSchema,
//   AdditionalPropertiesChildrenFormValues extends Record<string, unknown>,
//   AdditionalPropertiesChildrenValidationSchema extends yup.AnyObjectSchema,
//   CustomPropertiesChildrenFormValues extends Record<string, unknown>,
//   CustomPropertiesChildrenValidationSchema extends yup.AnyObjectSchema
// >(
//   modalProps?: UseFormModalProps
// ) => {
//   const { showModal } = useModals();
//   return React.useCallback(
//     (
//       formProps: CustomerFormProps<
//         AdditionalPropertiesFormValues,
//         AdditionalPropertiesValidationSchema,
//         CustomPropertiesFormValues,
//         CustomPropertiesValidationSchema,
//         AdditionalPropertiesChildrenFormValues,
//         AdditionalPropertiesChildrenValidationSchema,
//         CustomPropertiesChildrenFormValues,
//         CustomPropertiesChildrenValidationSchema
//       >
//     ) => {
//       showModal({
//         children: <PersonalDataFormController {...formProps} />,
//         size: 'large',
//         title: t('form.customerForm.modal.title'),
//         ...modalProps,
//       });
//     },
//     [modalProps, showModal]
//   );
// };
