import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    addToCartForm: {
      fields: {
        skus: {
          label: 'Taglia',
          placeholder: 'Seleziona una taglia',
        },
      },
      skus: {
        label: 'Taglia',
        placeholder: 'Seleziona una taglia',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
  },
  freeSample: {
    form: {
      insertData: 'Inserisci i dati per ricevere il tuo omaggio',
      maxQuantity: 'Puoi inserire massimo {{count}} campioni in totale.',
      selectSample: 'Seleziona i {{count}} campioni',
      selected: 'Hai selezionato',
    },
    registration: {
      guestTitle: 'Sei già registrato?',
      login: 'Login',
    },
  },
  misc: {
    backToHomeBlog: 'Torna alla home Magazine AmioAgio',
    doubleOptionAccess: 'Accedi o registrati subito',
    moreArticles: 'Altri articoli',
    noItemFound: 'Nessun elemento trovato',
    storeName: 'Hartmann',
  },
  product: {
    promo: 'Promo',
    size: 'Misura ',
  },
  welcome: 'Benvenuto su store-hartmann!',
};
